import { useState, useEffect } from "react";
import DashBoardBox from "../components/DashBoardBox";
import AnalyticsBoxHeader3 from "../components/AnalyticsBoxHeader3";
import { Box, Stack, useTheme, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../theme";
import Alert from "@mui/material/Alert";
import {
	ResponsiveContainer,
	XAxis,
	YAxis,
	Tooltip,
	Bar,
	Pie,
	PieChart,
	Cell,
	Legend,
	Sector,
} from "recharts";
import { BarChart } from "@mui/x-charts/BarChart";
import dayjs from "dayjs";
import { useAuth } from "../context/AuthProvider";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import threeWeekLookBack from "../hooks/three-week-lookback";
import filteredBarGraphData from "../hooks/fx-create-bar-graph-array";
import CustomSelect from "../styledcomponents/CustomSelect";

const data01 = [
	{
		name: "Group A",
		value: 400,
	},
	{
		name: "Group B",
		value: 300,
	},
	{
		name: "Group C",
		value: 300,
	},
	{
		name: "Group D",
		value: 200,
	},
	{
		name: "Group E",
		value: 278,
	},
	{
		name: "Group F",
		value: 189,
	},
];
const data02 = [
	{
		name: "Group A",
		value: 2400,
	},
	{
		name: "Group B",
		value: 4567,
	},
	{
		name: "Group C",
		value: 1398,
	},
	{
		name: "Group D",
		value: 9800,
	},
	{
		name: "Group E",
		value: 3908,
	},
	{
		name: "Group F",
		value: 4800,
	},
];

const Row1 = ({
	timecardData,
	error,
	errMsg,
	label,
	swData,
	professionalSearchFilters,
}) => {
	const start = dayjs().week(label).day(0).format("MM/DD/YYYY");
	const end = dayjs().week(label).day(6).format("MM/DD/YYYY");
	const pastTwelveMonthsLabels = Array.from({ length: 12 }, (_, i) =>
		dayjs().subtract(i, "month").format("M/YYYY")
	).reverse();
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const [tcDataRendered, settcDataRendered] = useState([]);
	const [activeIndex, setactiveIndex] = useState({
		activeIndex: 0,
	});
	const { user, setUser } = useAuth();
	const [selectedPie, setSelectedPie] = useState([]);
	const [weekSelect, setWeekSelect] = useState("Past 6 Weeks");
	const weeksObject = threeWeekLookBack();
	const handleChange = (event) => {
		setWeekSelect(event.target.value);
	};
	const { sixWkBkDesc } = threeWeekLookBack();

	//searches array by id prop, returns that object
	function searchById(array, id) {
		return array.find((item) => item.id === id);
	}

	//formats into pie chart data
	function reformatData(input) {
		return [
			{ name: "reg", value: input?.reg },
			{ name: "OT", value: input?.ot },
			{ name: "Sat", value: input?.sat },
			{ name: "Sun", value: input?.sun },
		];
	}

	const handleMonthChange = (key) => {
		const obj = searchById(swData, key); //returns object
		const pieData = reformatData(obj);
		setSelectedPie(pieData);
		// setSearchFilters((prev) => ({
		// 	...prev,
		// 	approved: event.target.value,
		//   }));
	};

	useEffect(() => {
		if (swData) {
			const pieData = reformatData(swData[0]);
			setSelectedPie(pieData);
		}
	}, [swData]);
	useEffect(() => {
		//setAllTimecards(data);
		let renderedData = [];
		let bargraphData = [];
		let result;
		//map through array of objectsw
		if (timecardData.length > 0) {
			renderedData = timecardData.map((timecard) => ({
				id: timecard._id,
				weekEndingString: timecard.weekEndingString,
				fullName: timecard.fullName,
				regularHours: timecard.calculations.regHours,
				OTHours: timecard.calculations.overtimeHours,
				saturdayHours: timecard.calculations.satHours,
				sundayHours: timecard.calculations.sunHours,
			}));
			// bargraphData = timecardData.map((timecard) => ({
			// 	id: timecard._id,
			// 	weekEnd: dayjs(timecard.weekEnd).format("MM/DD/YYYY"),
			// 	regHours: timecard.regHours,
			// 	overtimeHours: timecard.overtimeHours,
			// 	satHours: timecard.satHours,
			// 	sunHours: timecard.sunHours,
			// }));
			// result = filteredBarGraphData(sixWkBkDesc, bargraphData);
			settcDataRendered(renderedData);
		}
	}, [timecardData, user]);

	const timecardColumns = [
		{
			field: "weekEndingString",
			headerName: "Week Ending",
			flex: 0.4,
		},
		{
			field: "fullName",
			headerName: "Name",
			flex: 0.2,
		},
		{
			field: "regularHours",
			headerName: "Reg Hours",
			flex: 0.2,
		},
		{
			field: "OTHours",
			headerName: "OT",
			flex: 0.2,
			renderCell: (params) => `${params.value}`,
		},
		{
			field: "saturdayHours",
			headerName: "Sat",
			flex: 0.2,
			renderCell: (params) => `${params.value}`,
		},
		{
			field: "sundayHours",
			headerName: "Sun",
			flex: 0.2,
			renderCell: (params) => `${params.value}`,
		},
	];

	//const returnArray = projectAnalytics(unfilteredData);

	const COLORS = [
		colors.greenAccent[500],
		colors.redAccent[500],
		colors.blueAccent[500],
		colors.grey[500],
	];

	const renderActiveShape = (props) => {
		const RADIAN = Math.PI / 180;
		const {
			cx,
			cy,
			midAngle,
			innerRadius,
			outerRadius,
			startAngle,
			endAngle,
			fill,
			payload,
			percent,
			value,
		} = props;
		const sin = Math.sin(-RADIAN * midAngle);
		const cos = Math.cos(-RADIAN * midAngle);
		const sx = cx + (outerRadius + 10) * cos;
		const sy = cy + (outerRadius + 10) * sin;
		const mx = cx + (outerRadius + 30) * cos;
		const my = cy + (outerRadius + 30) * sin;
		const ex = mx + (cos >= 0 ? 1 : -1) * 22;
		const ey = my;
		const textAnchor = cos >= 0 ? "start" : "end";

		return (
			<g>
				<text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
					{payload.name}
				</text>
				<Sector
					cx={cx}
					cy={cy}
					innerRadius={innerRadius}
					outerRadius={outerRadius}
					startAngle={startAngle}
					endAngle={endAngle}
					fill={fill}
				/>
				<Sector
					cx={cx}
					cy={cy}
					startAngle={startAngle}
					endAngle={endAngle}
					innerRadius={outerRadius + 6}
					outerRadius={outerRadius + 10}
					fill={fill}
				/>
			</g>
		);
	};

	const onPieEnter = (_, index) => {
		setactiveIndex(index);
	};

	return (
		<>
			<DashBoardBox gridArea="a" theme={theme} colors={colors}>
				<AnalyticsBoxHeader3
					title={`My Unapproved Timecards`}
					subtitle="Datagrid"
				/>

				{errMsg && tcDataRendered.length === 0 ? (
					<Alert severity="error">{errMsg}</Alert>
				) : null}
				{tcDataRendered.length === 0 && !errMsg ? (
					<Alert severity="error">{"No data exists"}</Alert>
				) : null}

				<Box
					p="0 0.5rem"
					height="75%"
					sx={{
						"& .MuiDataGrid-root": {
							color: `${colors.grey[300]}`,
							border: "none",
						},
						"& .MuiDataGrid-cell": {
							borderBottom: `1px solid ${colors.grey[800]} !important`,
						},
						"& .MuiDataGrid-columnSeparator": {
							visibility: "hidden",
						},
					}}
				>
					{timecardData.length > 0 ? (
						<DataGrid
							rowHeight={25}
							columnHeaderHeight={25}
							hideFooter={true}
							rows={tcDataRendered}
							columns={timecardColumns}
						/>
					) : (
						<div />
					)}
				</Box>
			</DashBoardBox>
			<DashBoardBox gridArea="b" theme={theme} colors={colors}>
				<Stack direction="row" spacing={1} justifyContent="space-between">
					<AnalyticsBoxHeader3
						title={professionalSearchFilters.project}
						subtitle={
							professionalSearchFilters.role +
							"-" +
							professionalSearchFilters.classification
						}
					/>
					<Box sx={{ width: "120px", p: 1 }}>
						<CustomSelect
							value={sixWkBkDesc[4]}
							optionsArray={sixWkBkDesc}
							handleChange={handleChange}
						/>
					</Box>
				</Stack>
				{errMsg && timecardData.length === 0 ? (
					<Alert severity="error">{errMsg}</Alert>
				) : null}
				{timecardData.length === 0 && !errMsg ? (
					<Alert severity="error">{"No data exists"}</Alert>
				) : null}
				{timecardData.length > 0 ? (
					<ResponsiveContainer width="100%" height="70%">
						<BarChart
							series={[
								{ data: [35, 44, 24, 34] },
								{ data: [51, 6, 49, 30] },
								{ data: [15, 25, 30, 50] },
								{ data: [60, 50, 15, 25] },
							]}
							height={290}
							xAxis={[{ data: ["Q1", "Q2", "Q3", "Q4"], scaleType: "band" }]}
							margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
						/>
					</ResponsiveContainer>
				) : (
					<Box sx={{ display: "flex", justifyContent: "center" }}></Box>
				)}
			</DashBoardBox>
			<DashBoardBox gridArea="c" theme={theme} colors={colors}>
				<Stack
					direction="row"
					maxWidth="300px"
					spacing={1}
					justifyContent={"space-between"}
				>
					<Box>
						<AnalyticsBoxHeader3 title="Project" subtitle="Monthly Breakdown" />
					</Box>

					<Box sx={{ p: 1 }}>
						<CustomSelect
							value={pastTwelveMonthsLabels[11]}
							optionsArray={pastTwelveMonthsLabels}
							handleChange={handleMonthChange}
							width={"75px"}
						/>
					</Box>

					{/* <Box sx={{ width: '120px', p: 1 }}>
            <CustomSelect value={sixWkBkDesc[5]} optionsArray={sixWkBkDesc} />
          </Box> */}
				</Stack>
				{selectedPie.length === 0 && errMsg ? (
					<Alert severity="error">{errMsg}</Alert>
				) : null}
				{selectedPie.length === 0 && !errMsg ? (
					<Alert severity="error">{"No data exists"}</Alert>
				) : null}
				<Box
					mt="0.5rem"
					p="0 0.5rem"
					height="75%"
					sx={{
						"& .MuiDataGrid-root": {
							color: `${colors.grey[300]}`,
							border: "none",
						},
						"& .MuiDataGrid-cell": {
							borderBottom: `1px solid ${colors.grey[800]} !important`,
						},
						"& .MuiDataGrid-columnSeparator": {
							visibility: "hidden",
						},
					}}
				>
					<ResponsiveContainer width="90%" height="90%">
						<PieChart height={200} width={200}>
							{selectedPie.length > 0 ? (
								<Pie
									data={selectedPie}
									cx="50%"
									cy="50%"
									innerRadius={40}
									outerRadius={60}
									label
									labelKey="name"
									stroke="none"
									sx={{ border: "black" }}
									dataKey="value"
									activeIndex={activeIndex}
									activeShape={renderActiveShape}
									onMouseEnter={onPieEnter}
									onMouseLeave={() => setactiveIndex(null)}
								>
									{selectedPie.map((entry, index) => (
										<Cell
											key={`cell-${index}`} // Ensures a unique key
											fill={COLORS[index % COLORS.length]}
											sx={{ border: "none" }}
										/>
									))}
								</Pie>
							) : (
								<text
									x="50%"
									y="50%"
									textAnchor="middle"
									dominantBaseline="middle"
								>
									No Data Available
								</text>
							)}
							{/* <Pie
								data={selectedPie}
								dataKey="value"
								nameKey="name"
								cx="50%"
								cy="50%"
								innerRadius={60}
								outerRadius={80}
								fill="#82ca9d"
								label
							/>
							<Tooltip /> */}
						</PieChart>

						{/* <PieChart width={230} height={250}>
							<Pie
								data={data01}
								dataKey="value"
								nameKey="name"
								cx="50%"
								cy="50%"
								outerRadius={50}
								fill="#8884d8"
							/>
							<Pie
								data={data02}
								dataKey="value"
								nameKey="name"
								cx="50%"
								cy="50%"
								innerRadius={60}
								outerRadius={80}
								fill="#82ca9d"
								label
							/>
						</PieChart> */}
					</ResponsiveContainer>
				</Box>
			</DashBoardBox>
		</>
	);
};

export default Row1;
