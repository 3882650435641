import { useState, Fragment, useEffect, useCallback } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TopBarAdmin from "./../components/TopBarAdmin";
import ChooseTimeCardsForPayroll from "./../components/RunPayroll/ChooseTimeCardsForPayroll";
import SetRatesTimeCard from "./../components/RunPayroll/SetRatesTimeCard";
import { useAuth } from "../context/AuthProvider";
import ReviewForPayroll from "../components/RunPayroll/ReviewForPayroll";
import dayjs from "dayjs";
import Alert from "@mui/material/Alert";
import CheckIcon from "@mui/icons-material/Check";

const steps = ["Choose Timecards For Payroll", "Select Rate Types", "Approve"];

const RunPayrollPage = () => {
	const [activeStep, setActiveStep] = useState(0);
	const [finalStepTouched, setFinalStepTouched] = useState(false);
	const [skipped, setSkipped] = useState(new Set());
	const [statusMessage, setStatusMessage] = useState("");
	const [sentPayroll, setSentPayroll] = useState(false);
	const [notificationsNumber, setNotificationsNumber] = useState();
	const isStepOptional = (step) => {
		return step === 1;
	};

	const isStepSkipped = (step) => {
		return skipped.has(step);
	};
	const { api } = useAuth();
	const isoWeek = require("dayjs/plugin/isoWeek");
	dayjs.extend(isoWeek);
	const [timecards, setTimecards] = useState([]);
	const lastWeekend = dayjs().day() === 6 ? dayjs() : dayjs().day(6 - 7);
	const [searchFilters, setSearchFilters] = useState({
		approved: false,
		status: "Incomplete",
		project: "All Projects",
		weekNumberStart: dayjs().subtract(12, "week").isoWeek(),
		weekStartString: dayjs().subtract(4, "week").day(6).format("MM/DD/YYYY"),
		yearStart: dayjs().subtract(4, "week").year(),
		weekNumberEnd: lastWeekend.isoWeek(),
		weekEndString: lastWeekend.day(6).format("MM/DD/YYYY"),
		yearEnd: lastWeekend.year(),
	});
	const [filteredTimecards, setFilteredTimecards] = useState([]);
	const [middleStepArray, setMiddleStepArray] = useState([]);
	const [finalArray, setFinalArray] = useState([]);
	const [selectedDataID, setselectedDataID] = useState([]);
	const [errMsg, seterrMsg] = useState("");
	const [ratebook, setRatebook] = useState("");
	const [errState, setErrState] = useState(false);
	const [pagination, setPagination] = useState({
		page: 1,
		limit: 20,
		totaldocuments: 0,
	});
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);

	//snackbar position
	const [state, setState] = useState({
		open: false,
		vertical: "top",
		horizontal: "center",
	});
	const [listData, setListData] = useState(null);
	const handleNext = () => {
		let newSkipped = skipped;
		if (isStepSkipped(activeStep)) {
			newSkipped = new Set(newSkipped.values());
			newSkipped.delete(activeStep);
		}

		setActiveStep((prevActiveStep) => prevActiveStep + 1);
		setSkipped(newSkipped);
		if (activeStep + 1 === 2) {
			setFinalStepTouched(true);
		}
		if (activeStep + 1 === 3) {
			console.log(activeStep + 1);
			submitPayroll(finalArray);
			setSentPayroll(true);
			setStatusMessage("Payroll Sent");
		}
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleSkip = () => {
		if (!isStepOptional(activeStep)) {
			// You probably want to guard against something like this,
			// it should never occur unless someone's actively trying to break something.
			throw new Error("You can't skip a step that isn't optional.");
		}

		setActiveStep((prevActiveStep) => prevActiveStep + 1);
		setSkipped((prevSkipped) => {
			const newSkipped = new Set(prevSkipped.values());
			newSkipped.add(activeStep);
			return newSkipped;
		});
	};

	const handleClick = (newState) => () => {
		setState({ ...newState, open: true });
	};

	const handleReset = () => {
		setActiveStep(0);
		setFinalArray([]);
		setMiddleStepArray([]);
	};

	useEffect(() => {
		// Check for null or undefined arrays
		if (timecards && selectedDataID) {
			const filteredArray = timecards.filter(
				(obj) => obj && obj.id !== undefined && selectedDataID.includes(obj.id)
			);
			setFilteredTimecards(filteredArray);
		} else {
			console.log("One of the arrays is null or undefined.");
		}
	}, [selectedDataID, timecards]);

	useEffect(() => {
		setIsLoading(true);
		let renderedData = [];
		if (api.authenticated) {
			api

				.get(
					`/api/v1/timecardsv3/mytimecards/?page=1&limit=20&approved=${searchFilters.approved}&status=${searchFilters.status}&project=${searchFilters.project}&weekNumberStart=${searchFilters.weekNumberStart}&yearStart=${searchFilters.yearStart}&weekNumberEnd=${searchFilters.weekNumberEnd}&yearEnd=${searchFilters.yearEnd}`
				)
				.then((response) => {
					if (response?.data?.data[0]?.results) {
						console.log("response", response.data.data[0].results);
						renderedData = response?.data?.data[0].results.map((timecard) => {
							const formattedTimecard = {
								...timecard,
								id: timecard._id,
							};
							return formattedTimecard;
						});

						setPagination((prev) => ({
							...prev,
							page: response.data.data[0].page,
							limit: response.data.data[0].limit,
							totaldocuments: response.data.data[0].totaldocuments,
						}));
					}
				})
				.catch((err) => {
					console.log(err);
					setTimecards([]);
					setPagination((prev) => ({
						...prev,
						page: 1,
						totaldocuments: 0,
					}));
				})
				.finally(() => {
					setIsLoading(false);
					setTimecards(renderedData);
					if (renderedData.length === 0) {
						setError(true);
						seterrMsg("No Timecards Found");
						setPagination((prev) => ({
							...prev,
							page: 1,
							totaldocuments: 0,
						}));
					}
				});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [api, searchFilters]);

	const handleChildData = (
		payroll,
		selectedRate,
		selectedRate2,
		selectedRate3
	) => {
		console.log("payroll", payroll);
		setFinalArray((prevData) => {
			const updatedData = [...prevData];
			const index = updatedData.findIndex(
				(item) => item.timecardID === payroll.timecardID
			);

			if (index > -1) {
				updatedData[index] = {
					...payroll,
					selectedRate,
					selectedRate2,
					selectedRate3,
				};
			} else {
				updatedData.push({
					...payroll,
					selectedRate,
					selectedRate2,
					selectedRate3,
				});
			}

			return updatedData;
		});
	};

	// Mapping Create Stuff
	const submitPayroll = async (array) => {
		await api
			.post("/api/v1/payroll", JSON.stringify(array))
			.then((response) => {
				// const updatedPayroll = [...allTimecards, response.data.data];
				// setBookDetails(updatedPayroll);
				console.log("Payroll Created!");
				console.log(array);
				console.log(response);
				setStatusMessage("RateBook Created!");
			})
			.catch((error) => {
				console.log(error);
				setStatusMessage("An Error Occurred!");
				setErrState(true);
			});
	};

	console.log("finalArray", finalArray);

	useEffect(() => {
		if (filteredTimecards && filteredTimecards.length > 0) {
			console.log("filteredTimecards", filteredTimecards);
			if (!finalStepTouched) {
				const initialArray = filteredTimecards.map((timecard) => ({
					firstName: timecard.firstName,
					lastName: timecard.lastName,
					weekEnd: timecard.weekEndingString,
					title: timecard.fullName,
					weekNumber: timecard.weekNumber,
					month: timecard.month,
					year: timecard.year,
					timecardID: timecard.id,
					status: timecard.status,
					approvedBy: null,
					calculations: {
						p1RegHours: timecard.calculations.p1RegHours,
						p1OTHours: timecard.calculations.p1OTHours,
						p1SatHours: timecard.calculations.p1SatHours,
						p1SunHours: timecard.calculations.p1SunHours,
						p1Hours: timecard.calculations.p1Hours,
						p1RegRate: 0,
						p1OTRate: 0,
						p1SatRate: 0,
						p1SunRate: 0,
						p1RegWages: 0,
						p1OTWages: 0,
						p1SatWages: 0,
						p1SunWages: 0,
						p1TotalWages: 0,
						p2RegHours: timecard.calculations.p2RegHours,
						p2OTHours: timecard.calculations.p2OTHours,
						p2SatHours: timecard.calculations.p2SatHours,
						p2SunHours: timecard.calculations.p2SunHours,
						p2Hours: timecard.calculations.p2Hours,
						p2RegRate: 0,
						p2OTRate: 0,
						p2SatRate: 0,
						p2SunRate: 0,
						p2RegWages: 0,
						p2OTWages: 0,
						p2SatWages: 0,
						p2SunWages: 0,
						p2TotalWages: 0,
						p3RegHours: timecard.calculations.p3RegHours,
						p3OTHours: timecard.calculations.p3OTHours,
						p3SatHours: timecard.calculations.p3SatHours,
						p3SunHours: timecard.calculations.p3SunHours,
						p3Hours: timecard.calculations.p3Hours,
						p3RegRate: 0,
						p3OTRate: 0,
						p3SatRate: 0,
						p3SunRate: 0,
						p3RegWages: 0,
						p3OTWages: 0,
						p3SatWages: 0,
						p3SunWages: 0,
						p3TotalWages: 0,
						healthAndWelfare: 0,
						pension: 0,
						vacation: 0,
						other: 0,
						totalDeductions: 0,
						finalTotalWages: 0,
					},
					trade: timecard.role,
					classification: timecard.classification,
					employeeID: timecard.employeeID,
					trainingRate: 0,
					deductions: 0,
					totalHours: timecard.calculations.totalHours,
					totalWages: 0,
					project1: timecard.project1,
					project2: timecard.project2,
					project3: timecard.project3,
				}));
				// console.log('initialArray', initialArray);
				setMiddleStepArray(initialArray);
			} else {
				setMiddleStepArray(finalArray);
			}
		}
	}, [activeStep, filteredTimecards, finalArray, finalStepTouched]);

	console.log("middleStepArray", middleStepArray);
	console.log("finalArray", finalArray);
	console.log(finalStepTouched, "finalStepTouched");
	function checkFailed(then) {
		return function (responses) {
			const someFailed = responses.some((response) => response?.error);

			if (someFailed) {
				throw responses;
			}

			return then(responses);
		};
	}

	useEffect(() => {
		setIsLoading(true);
		if (api.authenticated) {
			Promise.all([
				api.get("/api/v1/personnel?sort=fullName&limit=1000"),
				api.get("/api/v1/projects/list"),
				api.get("/api/v1/ratebook/craftlist"),
				api.get("/api/v1/ratebook/classificationlist"),
				api.get("/api/v1/ratebook/booklist"),
			])
				.then(
					checkFailed(([personnel, project, craft, classification, book]) => {
						setListData((prev) => ({
							...prev,
							personnel,
							project,
							craft,
							classification,
							book,
						}));
					})
				)
				.catch((err) => {
					console.log("FAIL", err);
					setError(true);
					seterrMsg("Error Loading Data");
				})
				.finally(() => {
					setIsLoading(false);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [api]);
	console.log("listData", listData);
	useEffect(() => {
		if (api.authenticated) {
			api
				.get(`/api/v1/ratebook/?sort=lastName&limit=500`)
				.then((response) => {
					setRatebook(response.data.data);

					setError(false);
					seterrMsg("");
				})
				.catch((err) => {
					console.log("FAILED", { err });
					setError(true);
					seterrMsg("Error Loading Data");
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [api]);

	return (
		<Box
			sx={{
				alignItems: "center",
				p: 1,
			}}
		>
			<TopBarAdmin notificationsNumber={notificationsNumber} />
			<Box
				sx={{
					margin: "auto",
					width: "80%",
					height: "100%",
				}}
			>
				<Stepper activeStep={activeStep}>
					{steps.map((label, index) => {
						const stepProps = {};
						const labelProps = {};
						if (isStepOptional(index)) {
							labelProps.optional = (
								<Typography variant="caption">Optional</Typography>
							);
						}
						if (isStepSkipped(index)) {
							stepProps.completed = false;
						}
						return (
							<Step key={label} {...stepProps}>
								<StepLabel {...labelProps}>{label}</StepLabel>
							</Step>
						);
					})}
				</Stepper>
				{activeStep === 0 && (
					<Box sx={{ margin: "auto", width: "100%" }}>
						<ChooseTimeCardsForPayroll
							timecards={timecards}
							isLoading={isLoading}
							selectedDataID={selectedDataID}
							setselectedDataID={setselectedDataID}
						/>
					</Box>
				)}
				{activeStep === 1 ? (
					<Box>
						{
							<Typography>
								{finalArray?.length ? finalArray.length : 0} /{" "}
								{middleStepArray?.length ? middleStepArray.length : 0}
							</Typography>
						}
						{middleStepArray?.length > 0 &&
							middleStepArray.map((payroll, index) => (
								<SetRatesTimeCard
									key={index}
									onUpdate={handleChildData}
									payroll={payroll}
									listData={listData}
									handleSnackbar={handleClick}
									isLoading={isLoading}
									setIsLoading={setIsLoading}
									error={error}
									setError={setError}
									errMsg={errMsg}
									seterrMsg={seterrMsg}
									ratebook={ratebook}
									setRatebook={setRatebook}
									handleNext={handleNext}
								/>
							))}
					</Box>
				) : null}
				{activeStep === 2 ? (
					<Box>
						{finalArray?.length > 0 && (
							<ReviewForPayroll payrollData={finalArray} />
						)}
					</Box>
				) : null}

				{activeStep === steps.length ? (
					<Fragment>
						{sentPayroll && !errState ? (
							<Box>
								<Alert
									icon={<CheckIcon fontSize="inherit" />}
									severity="success"
								>
									Congratulations! You have sent in the payroll.
								</Alert>
								<Typography sx={{ mt: 2, mb: 1 }}>
									All steps completed - you&apos;re finished
								</Typography>
							</Box>
						) : (
							<Alert icon={<CheckIcon fontSize="inherit" />} severity="warning">
								An Error Occurred. Please resubmit.
							</Alert>
						)}

						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								pt: 2,
								overflow: "auto",
							}}
						>
							<Box sx={{ flex: "1 1 auto" }} />
							<Button onClick={handleReset}>Reset</Button>
						</Box>
					</Fragment>
				) : (
					<Fragment>
						<Typography>Step {activeStep + 1}</Typography>

						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "center",
								pt: 2,
								overflow: "auto",
							}}
						>
							<Button
								color="inherit"
								disabled={activeStep === 0}
								onClick={handleBack}
								sx={{ mr: 1 }}
							>
								Back
							</Button>
							<Box sx={{ flex: "1 1 auto" }} />
							{isStepOptional(activeStep) && (
								<Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
									Skip
								</Button>
							)}
							<Button onClick={handleNext}>
								{activeStep === steps.length - 1 ? "Submit" : "Next"}
							</Button>
						</Box>
					</Fragment>
				)}
			</Box>
		</Box>
	);
};

export default RunPayrollPage;
