import dayjs from 'dayjs';

const projectMapping = {
  sunp1start: 0,
  sunp1end: 0,
  monp1start: 1,
  monp1end: 1,
  tuep1start: 2,
  tuep1end: 2,
  wedp1start: 3,
  wedp1end: 3,
  thurp1start: 4,
  thurp1end: 4,
  frip1start: 5,
  frip1end: 5,
  satp1start: 6,
  satp1end: 6,
  sunp2start: 0,
  sunp2end: 0,
  monp2start: 1,
  monp2end: 1,
  tuep2start: 2,
  tuep2end: 2,
  wedp2start: 3,
  wedp2end: 3,
  thurp2start: 4,
  thurp2end: 4,
  frip2start: 5,
  frip2end: 5,
  satp2start: 6,
  satp2end: 6,
};

const projectConstants = [
  'sunp1start',
  'sunp1end',
  'monp1start',
  'monp1end',
  'tuep1start',
  'tuep1end',
  'wedp1start',
  'wedp1end',
  'thurp1start',
  'thurp1end',
  'frip1start',
  'frip1end',
  'satp1start',
  'satp1end',
  'sunp2start',
  'sunp2end',
  'monp2start',
  'monp2end',
  'tuep2start',
  'tuep2end',
  'wedp2start',
  'wedp2end',
  'thurp2start',
  'thurp2end',
  'frip2start',
  'frip2end',
  'satp2start',
  'satp2end',
];

export const updateDetailTimes = (object, weekEnd) => {
  let copy = { ...object };
  Object.keys(copy).forEach(function (key, index) {
    if (projectConstants.includes(key) && copy[key] !== null) {
      copy[key] = dayjs(weekEnd)
        .day(projectMapping[key])
        .hour(copy[key].hour())
        .minute(copy[key].minute());
    }
  });
  const output = {
    ...copy,
    weekEnding: dayjs(weekEnd),
    weekEndingString: weekEnd,
  };
  return output;
};

export const seedPayrollCreateV3Details = (
  personnel,
  weekEndString,
  oldTimecard
) => {
  console.log('seed payroll create v3 details');
  //takes in a weekend string in the format MM/DD/YYYY
  const updatedTimes = {
    //required
    name: `${personnel.fullName}_${weekEndString}`,
    firstName: personnel.firstName,
    lastName: personnel.lastName,
    fullName: personnel.fullName,
    role: personnel.role,
    classification: personnel.classification,
    employeeID: 22,
    weekNumber: dayjs(weekEndString).day(6).week(),
    month: dayjs(weekEndString).day(6).month(),
    year: dayjs(weekEndString).day(6).year(),
    weekEnding: dayjs(weekEndString).day(6).hour(0),
    //all unrequired
    sunp1start: oldTimecard.sunp1start
      ? dayjs(weekEndString)
          .day(0)
          .hour(oldTimecard.sunp1start ? oldTimecard.sunp1start.hour() : 0)
          .minute(oldTimecard.sunp1start ? oldTimecard.sunp1start.minute() : 0)
      : null,
    sunp1end: oldTimecard.sunp1end
      ? dayjs(weekEndString)
          .day(0)
          .hour(oldTimecard.sunp1end ? oldTimecard.sunp1end.hour() : 0)
          .minute(oldTimecard.sunp1end ? oldTimecard.sunp1end.minute() : 0)
      : null,
    monp1start: oldTimecard.monp1start
      ? dayjs(weekEndString)
          .day(1)
          .hour(oldTimecard.monp1start ? oldTimecard.monp1start.hour() : 0)
          .minute(oldTimecard.monp1start ? oldTimecard.monp1start.minute() : 0)
      : null,
    monp1end: oldTimecard.monp1end
      ? dayjs(weekEndString)
          .day(1)
          .hour(oldTimecard.monp1end ? oldTimecard.monp1end.hour() : 0)
          .minute(oldTimecard.monp1end ? oldTimecard.monp1end.minute() : 0)
      : null,
    tuep1start: oldTimecard.tuep1start
      ? dayjs(weekEndString)
          .day(2)
          .hour(oldTimecard.tuep1start ? oldTimecard.tuep1start.hour() : 0)
          .minute(oldTimecard.tuep1start ? oldTimecard.tuep1start.minute() : 0)
      : null,
    tuep1end: oldTimecard.tuep1end
      ? dayjs(weekEndString)
          .day(2)
          .hour(oldTimecard.tuep1end ? oldTimecard.tuep1end.hour() : 0)
          .minute(oldTimecard.tuep1end ? oldTimecard.tuep1end.minute() : 0)
      : null,
    wedp1start: oldTimecard.wedp1start
      ? dayjs(weekEndString)
          .day(3)
          .hour(oldTimecard.wedp1start ? oldTimecard.wedp1start.hour() : 0)
          .minute(oldTimecard.wedp1start ? oldTimecard.wedp1start.minute() : 0)
      : null,
    wedp1end: oldTimecard.wedp1end
      ? dayjs(weekEndString)
          .day(3)
          .hour(oldTimecard.wedp1end ? oldTimecard.wedp1end.hour() : 0)
          .minute(oldTimecard.wedp1end ? oldTimecard.wedp1end.minute() : 0)
      : null,
    thurp1start: oldTimecard.thurp1start
      ? dayjs(weekEndString)
          .day(4)
          .hour(oldTimecard.thurp1start ? oldTimecard.thurp1start.hour() : 0)
          .minute(
            oldTimecard.thurp1start ? oldTimecard.thurp1start.minute() : 0
          )
      : null,
    thurp1end: oldTimecard.thurp1end
      ? dayjs(weekEndString)
          .day(4)
          .hour(oldTimecard.thurp1end ? oldTimecard.thurp1end.hour() : 0)
          .minute(oldTimecard.thurp1end ? oldTimecard.thurp1end.minute() : 0)
      : null,
    frip1start: oldTimecard.frip1start
      ? dayjs(weekEndString)
          .day(5)
          .hour(oldTimecard.frip1start ? oldTimecard.frip1start.hour() : 0)
          .minute(oldTimecard.frip1start ? oldTimecard.frip1start.minute() : 0)
      : null,
    frip1end: oldTimecard.frip1end
      ? dayjs(weekEndString)
          .day(5)
          .hour(oldTimecard.frip1end ? oldTimecard.frip1end.hour() : 0)
          .minute(oldTimecard.frip1end ? oldTimecard.frip1end.minute() : 0)
      : null,
    satp1start: oldTimecard.satp1start
      ? dayjs(weekEndString)
          .day(6)
          .hour(oldTimecard.satp1start ? oldTimecard.satp1start.hour() : 0)
          .minute(oldTimecard.satp1start ? oldTimecard.satp1start.minute() : 0)
      : null,
    satp1end: oldTimecard.satp1end
      ? dayjs(weekEndString)
          .day(6)
          .hour(oldTimecard.satp1end ? oldTimecard.satp1end.hour() : 0)
          .minute(oldTimecard.satp1end ? oldTimecard.satp1end.minute() : 0)
      : null,
    sunp2start: oldTimecard.sunp2start
      ? dayjs(weekEndString)
          .day(0)
          .hour(oldTimecard.sunp2start ? oldTimecard.sunp2start.hour() : 0)
          .minute(oldTimecard.sunp2start ? oldTimecard.sunp2start.minute() : 0)
      : null,
    sunp2end: oldTimecard.sunp2end
      ? dayjs(weekEndString)
          .day(0)
          .hour(oldTimecard.sunp2end ? oldTimecard.sunp2end.hour() : 0)
          .minute(oldTimecard.sunp2end ? oldTimecard.sunp2end.minute() : 0)
      : null,
    monp2start: oldTimecard.monp2start
      ? dayjs(weekEndString)
          .day(1)
          .hour(oldTimecard.monp2start ? oldTimecard.monp2start.hour() : 0)
          .minute(oldTimecard.monp2start ? oldTimecard.monp2start.minute() : 0)
      : null,
    monp2end: oldTimecard.monp2end
      ? dayjs(weekEndString)
          .day(1)
          .hour(oldTimecard.monp2end ? oldTimecard.monp2end.hour() : 0)
          .minute(oldTimecard.monp2end ? oldTimecard.monp2end.minute() : 0)
      : null,
    tuep2start: oldTimecard.tuep2start
      ? dayjs(weekEndString)
          .day(2)
          .hour(oldTimecard.tuep2start ? oldTimecard.tuep2start.hour() : 0)
          .minute(oldTimecard.tuep2start ? oldTimecard.tuep2start.minute() : 0)
      : null,
    tuep2end: oldTimecard.tuep2end
      ? dayjs(weekEndString)
          .day(2)
          .hour(oldTimecard.tuep2end ? oldTimecard.tuep2end.hour() : 0)
          .minute(oldTimecard.tuep2end ? oldTimecard.tuep2end.minute() : 0)
      : null,
    wedp2start: oldTimecard.wedp2start
      ? dayjs(weekEndString)
          .day(3)
          .hour(oldTimecard.wedp2start ? oldTimecard.wedp2start.hour() : 0)
          .minute(oldTimecard.wedp2start ? oldTimecard.wedp2start.minute() : 0)
      : null,
    wedp2end: oldTimecard.wedp2end
      ? dayjs(weekEndString)
          .day(3)
          .hour(oldTimecard.wedp2end ? oldTimecard.wedp2end.hour() : 0)
          .minute(oldTimecard.wedp2end ? oldTimecard.wedp2end.minute() : 0)
      : null,
    thurp2start: oldTimecard.thurp2start
      ? dayjs(weekEndString)
          .day(4)
          .hour(oldTimecard.thurp2start ? oldTimecard.thurp2start.hour() : 0)
          .minute(
            oldTimecard.thurp2start ? oldTimecard.thurp2start.minute() : 0
          )
      : null,
    thurp2end: oldTimecard.thurp2end
      ? dayjs(weekEndString)
          .day(4)
          .hour(oldTimecard.thurp2end ? oldTimecard.thurp2end.hour() : 0)
          .minute(oldTimecard.thurp2end ? oldTimecard.thurp2end.minute() : 0)
      : null,
    frip2start: oldTimecard.frip2start
      ? dayjs(weekEndString)
          .day(5)
          .hour(oldTimecard.frip2start ? oldTimecard.frip2start.hour() : 0)
          .minute(oldTimecard.frip2start ? oldTimecard.frip2start.minute() : 0)
      : null,
    frip2end: oldTimecard.frip2end
      ? dayjs(weekEndString)
          .day(5)
          .hour(oldTimecard.frip2end ? oldTimecard.frip2end.hour() : 0)
          .minute(oldTimecard.frip2end ? oldTimecard.frip2end.minute() : 0)
      : null,
    satp2start: oldTimecard.satp2start
      ? dayjs(weekEndString)
          .day(6)
          .hour(oldTimecard.satp2start ? oldTimecard.satp2start.hour() : 0)
          .minute(oldTimecard.satp2start ? oldTimecard.satp2start.minute() : 0)
      : null,
    satp2end: oldTimecard.satp2end
      ? dayjs(weekEndString)
          .day(6)
          .hour(oldTimecard.satp2end ? oldTimecard.satp2end.hour() : 0)
          .minute(oldTimecard.satp2end ? oldTimecard.satp2end.minute() : 0)
      : null,
    sunp3start: oldTimecard.sunp3start
      ? dayjs(weekEndString)
          .day(0)
          .hour(oldTimecard.sunp3start ? oldTimecard.sunp3start.hour() : 0)
          .minute(oldTimecard.sunp3start ? oldTimecard.sunp3start.minute() : 0)
      : null,
    sunp3end: oldTimecard.sunp3end
      ? dayjs(weekEndString)
          .day(0)
          .hour(oldTimecard.sunp3end ? oldTimecard.sunp3end.hour() : 0)
          .minute(oldTimecard.sunp3end ? oldTimecard.sunp3end.minute() : 0)
      : null,
    monp3start: oldTimecard.monp3start
      ? dayjs(weekEndString)
          .day(1)
          .hour(oldTimecard.monp3start ? oldTimecard.monp3start.hour() : 0)
          .minute(oldTimecard.monp3start ? oldTimecard.monp3start.minute() : 0)
      : null,
    monp3end: oldTimecard.monp3end
      ? dayjs(weekEndString)
          .day(1)
          .hour(oldTimecard.monp3end ? oldTimecard.monp3end.hour() : 0)
          .minute(oldTimecard.monp3end ? oldTimecard.monp3end.minute() : 0)
      : null,
    tuep3start: oldTimecard.tuep3start
      ? dayjs(weekEndString)
          .day(2)
          .hour(oldTimecard.tuep3start ? oldTimecard.tuep3start.hour() : 0)
          .minute(oldTimecard.tuep3start ? oldTimecard.tuep3start.minute() : 0)
      : null,
    tuep3end: oldTimecard.tuep3end
      ? dayjs(weekEndString)
          .day(2)
          .hour(oldTimecard.tuep3end ? oldTimecard.tuep3end.hour() : 0)
          .minute(oldTimecard.tuep3end ? oldTimecard.tuep3end.minute() : 0)
      : null,
    wedp3start: oldTimecard.wedp3start
      ? dayjs(weekEndString)
          .day(3)
          .hour(oldTimecard.wedp3start ? oldTimecard.wedp3start.hour() : 0)
          .minute(oldTimecard.wedp3start ? oldTimecard.wedp3start.minute() : 0)
      : null,
    wedp3end: oldTimecard.wedp3end
      ? dayjs(weekEndString)
          .day(3)
          .hour(oldTimecard.wedp3end ? oldTimecard.wedp3end.hour() : 0)
          .minute(oldTimecard.wedp3end ? oldTimecard.wedp3end.minute() : 0)
      : null,
    thurp3start: oldTimecard.thurp3start
      ? dayjs(weekEndString)
          .day(4)
          .hour(oldTimecard.thurp3start ? oldTimecard.thurp3start.hour() : 0)
          .minute(
            oldTimecard.thurp3start ? oldTimecard.thurp3start.minute() : 0
          )
      : null,
    thurp3end: oldTimecard.thurp3end
      ? dayjs(weekEndString)
          .day(4)
          .hour(oldTimecard.thurp3end ? oldTimecard.thurp3end.hour() : 0)
          .minute(oldTimecard.thurp3end ? oldTimecard.thurp3end.minute() : 0)
      : null,
    frip3start: oldTimecard.frip3start
      ? dayjs(weekEndString)
          .day(5)
          .hour(oldTimecard.frip3start ? oldTimecard.frip3start.hour() : 0)
          .minute(oldTimecard.frip3start ? oldTimecard.frip3start.minute() : 0)
      : null,
    frip3end: oldTimecard.frip3end
      ? dayjs(weekEndString)
          .day(5)
          .hour(oldTimecard.frip3end ? oldTimecard.frip3end.hour() : 0)
          .minute(oldTimecard.frip3end ? oldTimecard.frip3end.minute() : 0)
      : null,
    satp3start: oldTimecard.satp3start
      ? dayjs(weekEndString)
          .day(6)
          .hour(oldTimecard.satp3start ? oldTimecard.satp3start.hour() : 0)
          .minute(oldTimecard.satp3start ? oldTimecard.satp3start.minute() : 0)
      : null,
    satp3end: oldTimecard.satp3end
      ? dayjs(weekEndString)
          .day(6)
          .hour(oldTimecard.satp3end ? oldTimecard.satp3end.hour() : 0)
          .minute(oldTimecard.satp3end ? oldTimecard.satp3end.minute() : 0)
      : null,
    breaksun: oldTimecard.breaksun ? oldTimecard.breaksun : 0,
    breakmon: oldTimecard.breakmon ? oldTimecard.breakmon : 0,
    breaktue: oldTimecard.breaktue ? oldTimecard.breaktue : 0,
    breakwed: oldTimecard.breakwed ? oldTimecard.breakwed : 0,
    breakthur: oldTimecard.breakthur ? oldTimecard.breakthur : 0,
    breakfri: oldTimecard.breakfri ? oldTimecard.breakfri : 0,
    breaksat: oldTimecard.breaksat ? oldTimecard.breaksat : 0,
    breakHourApplied: oldTimecard.breakHourApplied
      ? oldTimecard.breakHourApplied
      : 5,
    overtimeHourApplied: oldTimecard.overtimeHourApplied
      ? oldTimecard.overtimeHourApplied
      : 8,
    weekEndingString: weekEndString,
    project1: oldTimecard.project1 ? oldTimecard.project1 : '',
    project2: oldTimecard.project2 ? oldTimecard.project2 : '',
    project3: oldTimecard.project3 ? oldTimecard.project3 : '',
    calculations: {},
  };
  return updatedTimes;
};
