//functional review for payroll under joy mui
import { useMediaQuery } from "@mui/material";
import React from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import { PDFViewer } from "@react-pdf/renderer";
import dayjs from "dayjs";

// Create styles
const styles = StyleSheet.create({
	page: {
		flexDirection: "column",
		//backgroundColor: '#E4E4E4'
	},
	column: {
		flex: 1, // Each column will take up equal space
		padding: 10,
		borderWidth: 1,
		borderColor: "#000",
	},
	row: {
		flexDirection: "row",
	},
	text: {
		fontSize: "10px",
		px: 1,
	},
	spacer: {
		padding: "0 10px",
	},
	project: {
		padding: "0 10px",
		alignItems: "right",
	},
	container: {
		flexDirection: "row",
		padding: "10px 10px",
	},
	section: {
		margin: 10,
		padding: 10,
		flexDirection: "row",
	},
	bold: {
		fontWeight: "bold",
	},
	wages: {
		flexDirection: "row",
		padding: "0px 10px",
	},
	boldSmall: {
		fontSize: 10, // Adjust size as needed
		fontWeight: "bold",
	},
});

const ReviewForPayroll = ({ payrollData }) => {
	const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
	console.log("payrollData", payrollData);

	const renderPage = (index) => {
		const limit = 4; // Change page after 4 entries (0-based index)
		return index % (limit + 1) === 0; // Returns true on every 5th entry
	};

	return (
		// <PDFViewer style={{ width: "100%", height: "100vh" }}>
		// 	<Document>
		// 		{payrollData.map((timecard, index) => {
		// 			return (
		// 				<Page key={index} page={Math.floor(index / 5) + 1}>
		// 					<View style={styles.column}>
		// 						<View style={styles.row}>
		// 							<Text style={{ fontSize: "10px" }}>#{index + 1}</Text>
		// 							<View style={styles.spacer}>
		// 								<Text style={styles.text}>
		// 									Name:
		// 									{timecard.firstName}
		// 									{timecard.lastName}
		// 								</Text>
		// 							</View>
		// 							<View style={styles.spacer}>
		// 								<Text style={styles.text}>
		// 									Week Ending: {timecard.weekEnd}
		// 								</Text>
		// 							</View>
		// 							<View style={styles.spacer}>
		// 								<Text style={styles.text}>
		// 									Total Hours: {timecard.totalHours}
		// 								</Text>
		// 							</View>
		// 						</View>
		// 						<br />
		// 						<View style={styles.container}>
		// 							<View style={styles.project}>
		// 								<Text style={styles.text}>
		// 									Regular Hours: {timecard.calculations.p1RegHours}hrs @ $
		// 									{timecard.calculations.p1RegRate} = $
		// 									{timecard.calculations.p1RegWages}
		// 								</Text>
		// 								<Text style={styles.text}>
		// 									Overtime Hours: {timecard.calculations.p1OTHours}hrs @ $
		// 									{timecard.calculations.p1OTRate} = $
		// 									{timecard.calculations.p1OTWages}
		// 								</Text>
		// 								<Text style={styles.text}>
		// 									Sat Hours: {timecard.calculations.p1SatHours}hrs @ $
		// 									{timecard.calculations.p1SatRate} = $
		// 									{timecard.calculations.p1SatWages}
		// 								</Text>
		// 								<Text style={styles.text}>
		// 									Sunday Hours: {timecard.calculations.p1SunHours}hrs @ $
		// 									{timecard.calculations.p1SunRate} = $
		// 									{timecard.calculations.p1SunWages}
		// 								</Text>
		// 							</View>
		// 							<View style={styles.project}>
		// 								<Text style={styles.text}>
		// 									Regular Hours: {timecard.calculations.p2RegHours}hrs @ $
		// 									{timecard.calculations.p2RegRate} = $
		// 									{timecard.calculations.p2RegWages}
		// 								</Text>
		// 								<Text style={styles.text}>
		// 									Overtime Hours: {timecard.calculations.p2OTHours}hrs @ $
		// 									{timecard.calculations.p2OTRate} = $
		// 									{timecard.calculations.p2OTWages}
		// 								</Text>
		// 								<Text style={styles.text}>
		// 									Sat Hours: {timecard.calculations.p2SatHours}hrs @ $
		// 									{timecard.calculations.p2SatRate} = $
		// 									{timecard.calculations.p2SatWages}
		// 								</Text>
		// 								<Text style={styles.text}>
		// 									Sunday Hours: {timecard.calculations.p2SunHours}hrs @ $
		// 									{timecard.calculations.p2SunRate} = $
		// 									{timecard.calculations.p2SunWages}
		// 								</Text>
		// 							</View>
		// 							<View style={styles.project}>
		// 								<Text style={styles.text}>
		// 									Regular Hours: {timecard.calculations.p3RegHours}hrs @ $
		// 									{timecard.calculations.p3RegRate} = $
		// 									{timecard.calculations.p3RegWages}
		// 								</Text>
		// 								<Text style={styles.text}>
		// 									Overtime Hours: {timecard.calculations.p3OTHours}hrs @ $
		// 									{timecard.calculations.p3OTRate} = $
		// 									{timecard.calculations.p3OTWages}
		// 								</Text>
		// 								<Text style={styles.text}>
		// 									Sat Hours: {timecard.calculations.p3SatHours}hrs @ $
		// 									{timecard.calculations.p3SatRate} = $
		// 									{timecard.calculations.p3SatWages}
		// 								</Text>
		// 								<Text style={styles.text}>
		// 									Sunday Hours: {timecard.calculations.p3SunHours}hrs @ $
		// 									{timecard.calculations.p3SunRate} = $
		// 									{timecard.calculations.p3SunWages}
		// 								</Text>
		// 							</View>
		// 						</View>
		// 					</View>
		// 				</Page>
		// 			);
		// 		})}
		// 	</Document>
		// </PDFViewer>
		<PDFViewer style={{ width: "100%", height: "100vh" }}>
			<Document>
				{Array.from(
					{ length: Math.ceil(payrollData.length / 4) },
					(_, pageIndex) => (
						<Page key={pageIndex} size="A4">
							<View style={styles.column}>
								{payrollData
									.slice(pageIndex * 5, pageIndex * 5 + 5) // Get 5 entries per page
									.map((timecard, index) => (
										<View>
											<View key={index} style={styles.row}>
												<Text style={{ fontSize: "10px" }}>
													#{pageIndex * 4 + index + 1}
												</Text>
												<View style={styles.spacer}>
													<Text style={styles.text}>
														Name: {timecard.firstName} {timecard.lastName}
													</Text>
												</View>
												<View style={styles.spacer}>
													<Text style={styles.text}>
														Week Ending: {timecard.weekEnd}
													</Text>
												</View>
												<View style={styles.spacer}>
													<Text style={styles.text}>
														Total Hours: {timecard.totalHours}
													</Text>
												</View>
												<View style={styles.wages}>
													<Text style={styles.text}>Total Wages: </Text>
													<Text
														style={[
															styles.bold,
															{ fontSize: 10, fontWeight: "bold" },
														]}
													>
														${timecard.totalWages}
													</Text>
												</View>
											</View>
											<br />
											<View style={styles.container}>
												<View style={styles.project}>
													<Text style={styles.text}>
														Regular Hours: {timecard.calculations.p1RegHours}hrs
														@ ${timecard.calculations.p1RegRate} = $
														{timecard.calculations.p1RegWages}
													</Text>
													<Text style={styles.text}>
														Overtime Hours: {timecard.calculations.p1OTHours}hrs
														@ ${timecard.calculations.p1OTRate} = $
														{timecard.calculations.p1OTWages}
													</Text>
													<Text style={styles.text}>
														Sat Hours: {timecard.calculations.p1SatHours}hrs @ $
														{timecard.calculations.p1SatRate} = $
														{timecard.calculations.p1SatWages}
													</Text>
													<Text style={styles.text}>
														Sunday Hours: {timecard.calculations.p1SunHours}hrs
														@ ${timecard.calculations.p1SunRate} = $
														{timecard.calculations.p1SunWages}
													</Text>
												</View>
												<View style={styles.project}>
													<Text style={styles.text}>
														Regular Hours: {timecard.calculations.p2RegHours}hrs
														@ ${timecard.calculations.p2RegRate} = $
														{timecard.calculations.p2RegWages}
													</Text>
													<Text style={styles.text}>
														Overtime Hours: {timecard.calculations.p2OTHours}hrs
														@ ${timecard.calculations.p2OTRate} = $
														{timecard.calculations.p2OTWages}
													</Text>
													<Text style={styles.text}>
														Sat Hours: {timecard.calculations.p2SatHours}hrs @ $
														{timecard.calculations.p2SatRate} = $
														{timecard.calculations.p2SatWages}
													</Text>
													<Text style={styles.text}>
														Sunday Hours: {timecard.calculations.p2SunHours}hrs
														@ ${timecard.calculations.p2SunRate} = $
														{timecard.calculations.p2SunWages}
													</Text>
												</View>
												<View style={styles.project}>
													<Text style={styles.text}>
														Regular Hours: {timecard.calculations.p3RegHours}hrs
														@ ${timecard.calculations.p3RegRate} = $
														{timecard.calculations.p3RegWages}
													</Text>
													<Text style={styles.text}>
														Overtime Hours: {timecard.calculations.p3OTHours}hrs
														@ ${timecard.calculations.p3OTRate} = $
														{timecard.calculations.p3OTWages}
													</Text>
													<Text style={styles.text}>
														Sat Hours: {timecard.calculations.p3SatHours}hrs @ $
														{timecard.calculations.p3SatRate} = $
														{timecard.calculations.p3SatWages}
													</Text>
													<Text style={styles.text}>
														Sunday Hours: {timecard.calculations.p3SunHours}hrs
														@ ${timecard.calculations.p3SunRate} = $
														{timecard.calculations.p3SunWages}
													</Text>
												</View>
											</View>
										</View>
									))}
							</View>
						</Page>
					)
				)}
			</Document>
		</PDFViewer>
	);
};

export default ReviewForPayroll;
