import React from 'react';
import { Box, Typography, Stack } from '@mui/joy';
import { tokens } from '../theme';
import Select from '@mui/joy/Select';
import Input from '@mui/joy/Input';
import Option from '@mui/joy/Option';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useState } from 'react';
import Header from '../components/Header';
import SendIcon from '@mui/icons-material/Send';
import { DateField } from '@mui/x-date-pickers/DateField';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useAuth } from '../context/AuthProvider';
import { CssVarsProvider, extendTheme } from '@mui/joy/styles';
import { extendTheme as extendJoyTheme } from '@mui/joy/styles';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import {
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  experimental_extendTheme as extendMaterialTheme,
  THEME_ID,
} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Chip from '@mui/joy/Chip';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';

const joyTheme = extendJoyTheme({
  colorSchemes: {
    light: {
      palette: {
        primary: {
          main: '#007FFF',
        },
        neutral: {
          plainColor: '#000',
          main: '#666',
        },
      },
    },
  },
});

const muiTheme = createTheme({
  palette: {
    primary: {
      main: '#007FFF',
    },
  },
});

const typesList = ['By Role', 'Custom'];

const details = {
  craft: null,
  classification: null,
  expirationDate: null,
  basicRate: '',
  healthAndWelfare: '',
  pension: '',
  vacationAndHoliday: '',
  training: '',
  otherPayments: '',
  hours: '',
  regRate: '',
  OTRate: '',
  satRate: '',
  sunRate: '',
  dateInc1: null,
  increase1: '',
  dateInc2: null,
  increase2: '',
  dateInc3: null,
  increase3: '',
  dateInc4: null,
  increase4: '',
  dateInc5: null,
  increase5: '',
  book: '',
  issuedate: null,
  mappingType: 'By Role',
};

const AddBookSchema = Yup.object().shape({
  craft: Yup.string().required('Required'),
  classification: Yup.string().required('Required'),
  book: Yup.string().required('Required'),
  issuedate: Yup.date().required('Required'),
  expirationDate: Yup.date().required('Required'),
  training: Yup.number().required('Required'),
  basicRate: Yup.number().required('Required'),
  regRate: Yup.number().required('Required'),
  OTRate: Yup.number().required('Required'),
  satRate: Yup.number().required('Required'),
  sunRate: Yup.number().required('Required'),
  dateInc1: Yup.date().nullable(true),
  increase1: Yup.number().nullable(true),
  dateInc2: Yup.date().nullable(true),
  increase2: Yup.number().nullable(true),
  dateInc3: Yup.date().nullable(true),
  increase3: Yup.number().nullable(true),
  dateInc4: Yup.date().nullable(true),
  increase4: Yup.number().nullable(true),
  dateInc5: Yup.date().nullable(true),
  increase5: Yup.number().nullable(true),
});

const AddCustomBookSchema = Yup.object().shape({
  craft: Yup.string().nullable(true),
  classification: Yup.string().nullable(true),
  book: Yup.string().required('Required'),
  issuedate: Yup.date().required('Required'),
  expirationDate: Yup.date().required('Required'),
  training: Yup.number().required('Required'),
  basicRate: Yup.number().required('Required'),
  regRate: Yup.number().required('Required'),
  OTRate: Yup.number().required('Required'),
  satRate: Yup.number().required('Required'),
  sunRate: Yup.number().required('Required'),
  dateInc1: Yup.date().nullable(true),
  increase1: Yup.number().nullable(true),
  dateInc2: Yup.date().nullable(true),
  increase2: Yup.number().nullable(true),
  dateInc3: Yup.date().nullable(true),
  increase3: Yup.number().nullable(true),
  dateInc4: Yup.date().nullable(true),
  increase4: Yup.number().nullable(true),
  dateInc5: Yup.date().nullable(true),
  increase5: Yup.number().nullable(true),
});

const AddBookFormMui = ({ listData }) => {
  const { api } = useAuth();
  const materialTheme = extendMaterialTheme();
  const [mappingDetails, setMappingDetails] = useState(details);
  const [isCustom, setIsCustom] = useState(false);
  const [statusMessage, setStatusMessage] = useState(null);
  const [bookDetails, setBookDetails] = useState(details);
  const [validationSchema, setValidationSchema] = useState(AddBookSchema);
  console.log(listData, 'listData');

  const formik = useFormik({
    initialValues: bookDetails,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values, 'values');
      createRatebook(values);
    },
  });

  // Mapping Create Stuff
  const createRatebook = async (details) => {
    api
      .post('/api/v1/ratebook', JSON.stringify(details))
      .then(() => {
        // const updatedPayroll = [...allTimecards, response.data.data];
        // setBookDetails(updatedPayroll);
        setStatusMessage('RateBook Created!');
      })
      .catch((error) => {
        setStatusMessage('An Error Occurred!');
      });
  };

  return (
    <MaterialCssVarsProvider theme={{ [THEME_ID]: materialTheme }}>
      <CssVarsProvider theme={joyTheme}>
        <CssBaseline />
        <ThemeProvider theme={muiTheme}>
          <Box sx={{ bgcolor: 'white', borderRadius: 2 }}>
            <Header title={'Create A New Ratebook'} subtitle={'Enter Below'} />
            <Typography variant='h6' sx={{ mt: 2, color: 'green' }}>
              {' '}
              Required
            </Typography>
            <form onSubmit={formik.handleSubmit}>
              <FormControl sx={{ width: '100%' }}>
                {/* <InputLabel id='mappingTypeLabel'>Rate Book Name</InputLabel> */}
                <Input
                  id='outlined-basic'
                  placeholder='Enter Name For Rate Book'
                  name='book'
                  variant='outlined'
                  slotProps={{
                    style: {
                      padding: 8,
                    },
                  }}
                  onChange={formik.handleChange}
                  value={formik.values.book}
                  error={formik.touched.book && Boolean(formik.errors.book)}
                />
              </FormControl>
              {isCustom ? null : (
                <Select
                  labelId='tradeLabel'
                  variant='outlined'
                  placeholder='Select From Personnel List'
                  slotProps={{
                    listbox: {
                      variant: 'outlined',
                    },
                  }}
                  id='craft'
                  name='craft'
                  value={formik.values.craft}
                  error={formik.touched.craft && Boolean(formik.errors.craft)}
                  helperText={formik.touched.craft && formik.errors.craft}
                  onChange={(e, newValue) => {
                    console.log(e, 'e');
                    formik.setFieldValue('craft', newValue);
                  }}>
                  {listData.craft.data.data.map((name, index) => (
                    <Option key={index} value={name}>
                      {name}
                    </Option>
                  ))}
                </Select>
              )}

              {isCustom ? null : (
                <Select
                  labelId='classificationLabel'
                  id='classification'
                  placeholder='Classification'
                  name='classification'
                  value={formik.values.classification}
                  error={
                    formik.touched.classification &&
                    Boolean(formik.errors.classification)
                  }
                  helperText={
                    formik.touched.classification &&
                    formik.errors.classification
                  }
                  onChange={(e, newValue) => {
                    console.log(e, 'e');
                    formik.setFieldValue('classification', newValue);
                  }}>
                  {listData.classification.data.data.map((name, index) => (
                    <Option key={index} value={name}>
                      {name}
                    </Option>
                  ))}
                </Select>
              )}
              <Stack direction='row'>
                <FormControl sx={{ width: '50%' }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateField
                      label='Issue Date'
                      name='issuedate'
                      slotProps={{
                        textField: {
                          size: 'small', // Size customization
                          variant: 'outlined', // Ensure consistent style
                        },
                      }}
                      value={formik.values.issuedate}
                      onChange={(newValue) => {
                        formik.setFieldValue('issuedate', newValue);
                      }}
                      format='MM-DD-YYYY'
                      error={
                        formik.touched.issuedate &&
                        Boolean(formik.errors.issuedate)
                      }
                    />
                  </LocalizationProvider>
                </FormControl>
                <FormControl sx={{ width: '50%' }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateField
                      name='expirationDate'
                      label='Expiration Date'
                      size='small'
                      slotProps={{
                        textField: { size: 'small' },
                      }}
                      // onChange={(value) =>
                      //   updateBookDetails({
                      //     expirationDate: value,
                      //   })
                      // }
                      value={formik.values.expirationDate}
                      onChange={(newValue) => {
                        formik.setFieldValue('expirationDate', newValue);
                      }}
                      format='MM-DD-YYYY'
                      error={
                        formik.touched.expirationDate &&
                        Boolean(formik.errors.expirationDate)
                      }
                    />
                  </LocalizationProvider>
                </FormControl>
              </Stack>
              <Stack direction='row'>
                <FormControl sx={{ width: '33.33%' }}>
                  <Input
                    id='outlined-basic'
                    placeholder='Training Rate'
                    type='number'
                    name='training'
                    variant='outlined'
                    slotProps={{
                      style: {
                        padding: 9,
                      },
                    }}
                    // onChange={(e) => updateBookDetails({ training: e.target.value })}
                    value={formik.values.training}
                    error={
                      formik.touched.training && Boolean(formik.errors.training)
                    }
                    onChange={formik.handleChange}
                  />
                </FormControl>
                <FormControl sx={{ width: '33.33%' }}>
                  <Input
                    id='outlined-basic'
                    placeholder='Basic Rate'
                    type='number'
                    name='basicRate'
                    variant='outlined'
                    inputProps={{
                      style: {
                        padding: 9,
                      },
                    }}
                    value={formik.values.basicRate}
                    error={
                      formik.touched.basicRate &&
                      Boolean(formik.errors.basicRate)
                    }
                    onChange={formik.handleChange}
                  />
                </FormControl>
                <FormControl sx={{ width: '33.33%' }}>
                  <Input
                    id='outlined-basic'
                    placeholder='Regular Rate'
                    type='number'
                    variant='outlined'
                    name='regRate'
                    inputProps={{
                      style: {
                        padding: 9,
                      },
                    }}
                    value={formik.values.regRate}
                    error={
                      formik.touched.regRate && Boolean(formik.errors.regRate)
                    }
                    onChange={formik.handleChange}
                  />
                </FormControl>
              </Stack>
              <Stack direction='row'>
                <FormControl sx={{ width: '33.33%' }}>
                  <Input
                    id='outlined-basic'
                    placeholder='OT Rate'
                    type='number'
                    variant='outlined'
                    name='OTRate'
                    inputProps={{
                      style: {
                        padding: 9,
                      },
                    }}
                    value={formik.values.OTRate}
                    error={
                      formik.touched.OTRate && Boolean(formik.errors.OTRate)
                    }
                    onChange={formik.handleChange}
                  />
                </FormControl>
                <FormControl sx={{ width: '33.33%' }}>
                  <Input
                    id='outlined-basic'
                    placeholder='Saturday Rate'
                    type='number'
                    name='satRate'
                    variant='outlined'
                    inputProps={{
                      style: {
                        padding: 9,
                      },
                    }}
                    value={formik.values.satRate}
                    error={
                      formik.touched.satRate && Boolean(formik.errors.satRate)
                    }
                    onChange={formik.handleChange}
                  />
                </FormControl>
                <FormControl sx={{ width: '33.33%' }}>
                  <Input
                    id='outlined-basic'
                    placeholder='Sunday Rate'
                    type='number'
                    variant='outlined'
                    name='sunRate'
                    inputProps={{
                      style: {
                        padding: 9,
                      },
                    }}
                    value={formik.values.sunRate}
                    error={
                      formik.touched.sunRate && Boolean(formik.errors.sunRate)
                    }
                    onChange={formik.handleChange}
                  />
                </FormControl>
              </Stack>
              <Typography variant='h6' sx={{ mt: 2, color: 'green' }}>
                {' '}
                Optional Predetermined Increases
              </Typography>
              <Stack direction='row'>
                <FormControl sx={{ width: '30%' }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateField
                      label='Date 1'
                      slotProps={{
                        textField: { size: 'small' },
                      }}
                      name='dateInc1'
                      value={formik.values.dateInc1}
                      onChange={(newValue) => {
                        formik.setFieldValue('dateInc1', newValue);
                      }}
                      format='MM-DD-YYYY'
                      error={Boolean(formik.errors.dateInc1)}></DateField>
                  </LocalizationProvider>
                </FormControl>
                <FormControl sx={{ width: '20%' }}>
                  <Input
                    id='outlined-basic'
                    placeholder='Rate 1'
                    type='number'
                    sx={{
                      '--Input-minHeight': '40px',
                    }}
                    variant='outlined'
                    name='increase1'
                    value={formik.values.increase1}
                    onChange={formik.handleChange}
                    format='MM-DD-YYYY'
                    error={Boolean(formik.errors.increase1)}
                  />
                </FormControl>
                <FormControl sx={{ width: '30%' }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateField
                      label='Date 2'
                      slotProps={{
                        textField: { size: 'small' },
                      }}
                      name='dateInc2'
                      value={formik.values.dateInc2}
                      onChange={(newValue) => {
                        formik.setFieldValue('dateInc2', newValue);
                      }}
                      format='MM-DD-YYYY'
                      error={Boolean(formik.errors.dateInc2)}
                    />
                  </LocalizationProvider>
                </FormControl>
                <FormControl sx={{ width: '20%' }}>
                  <Input
                    id='outlined-basic'
                    placeholder='Rate 2'
                    type='number'
                    variant='outlined'
                    sx={{
                      '--Input-minHeight': '40px',
                    }}
                    name='increase2'
                    value={formik.values.increase2}
                    onChange={formik.handleChange}
                    format='MM-DD-YYYY'
                    error={Boolean(formik.errors.increase2)}
                  />
                </FormControl>
              </Stack>

              <Stack direction='row'>
                <FormControl sx={{ width: '30%' }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateField
                      label='Date 3'
                      name='dateInc3'
                      value={formik.values.dateInc3}
                      onChange={(newValue) => {
                        formik.setFieldValue('dateInc3', newValue);
                      }}
                      slotProps={{
                        textField: { size: 'small' },
                      }}
                      error={false}
                    />
                  </LocalizationProvider>
                </FormControl>
                <FormControl sx={{ width: '20%' }}>
                  <Input
                    id='outlined-basic'
                    placeholder='Rate 3'
                    type='number'
                    variant='outlined'
                    name='increase3'
                    sx={{
                      '--Input-minHeight': '40px',
                    }}
                    value={formik.values.increase3}
                    onChange={formik.handleChange}
                  />
                </FormControl>
                <FormControl sx={{ width: '30%' }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateField
                      label='Date 4'
                      slotProps={{
                        textField: { size: 'small' },
                      }}
                      error={false}
                      name='dateInc4'
                      value={formik.values.dateInc4}
                      onChange={(newValue) => {
                        formik.setFieldValue('dateInc4', newValue);
                      }}
                    />
                  </LocalizationProvider>
                </FormControl>
                <FormControl sx={{ width: '20%' }}>
                  <Input
                    id='outlined-basic'
                    placeholder='Rate 4'
                    type='number'
                    variant='outlined'
                    sx={{
                      '--Input-minHeight': '40px',
                    }}
                    name='increase4'
                    value={formik.values.increase4}
                    onChange={formik.handleChange}
                  />
                </FormControl>
              </Stack>
              <Stack direction='row'>
                <FormControl sx={{ width: '30%' }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateField
                      label='Date 5'
                      slotProps={{
                        textField: { size: 'small' },
                      }}
                      error={false}
                      name='dateInc5'
                      value={formik.values.dateInc5}
                      onChange={(newValue) => {
                        formik.setFieldValue('dateInc5', newValue);
                      }}
                    />
                  </LocalizationProvider>
                </FormControl>
                <FormControl sx={{ width: '20%' }}>
                  <Input
                    id='outlined-basic'
                    placeholder='Rate 5'
                    type='number'
                    variant='outlined'
                    sx={{
                      '--Input-minHeight': '40px',
                    }}
                    name='increase5'
                    value={formik.values.increase5}
                    onChange={formik.handleChange}
                  />
                </FormControl>
                <FormControl sx={{ width: '25%' }}>
                  <Input
                    id='outlined-basic'
                    placeholder='Health & Welfare'
                    type='number'
                    variant='outlined'
                    sx={{
                      '--Input-minHeight': '40px',
                    }}
                    name='healthAndWelfare'
                    value={formik.values.healthAndWelfare}
                    onChange={formik.handleChange}
                  />
                </FormControl>
                <FormControl sx={{ width: '25%' }}>
                  <Input
                    id='outlined-basic'
                    placeholder='Vacation'
                    type='number'
                    variant='outlined'
                    sx={{
                      '--Input-minHeight': '40px',
                    }}
                    name='vacationAndHoliday'
                    value={formik.values.vacationAndHoliday}
                    onChange={formik.handleChange}
                  />
                </FormControl>
              </Stack>
              <Stack direction='row'>
                <FormControl sx={{ width: '25%' }}>
                  <Input
                    id='outlined-basic'
                    placeholder='Other'
                    type='number'
                    variant='outlined'
                    sx={{
                      '--Input-minHeight': '40px',
                    }}
                    name='other'
                    value={formik.values.other}
                    onChange={formik.handleChange}
                  />
                </FormControl>
                <FormControl sx={{ width: '25%' }}>
                  <Input
                    id='outlined-basic'
                    placeholder='Pension'
                    type='number'
                    variant='outlined'
                    sx={{
                      '--Input-minHeight': '40px',
                    }}
                    name='pension'
                    value={formik.values.pension}
                    onChange={formik.handleChange}
                  />
                </FormControl>
              </Stack>
              <Box textAlign='center'>
                <Typography variant='h6' sx={{ mt: 2, color: 'green' }}>
                  {statusMessage}
                </Typography>
                <Button
                  variant='contained'
                  sx={{ backgroundColor: 'primary', m: 1 }}
                  onClick={formik.handleSubmit}
                  endIcon={<SendIcon />}>
                  Send
                </Button>
              </Box>
            </form>
          </Box>
        </ThemeProvider>
      </CssVarsProvider>
    </MaterialCssVarsProvider>
  );
};

export default AddBookFormMui;
