import { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { useAuth } from "../context/AuthProvider";
import Box from "@mui/material/Box";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const CheckoutButton = ({ priceId, subscriptionId }) => {
	console.log(priceId);
	console.log(subscriptionId);
	const [loading, setLoading] = useState(false);
	const { api } = useAuth();
	const [sessionId, setSessionId] = useState("");
	const [Url, setUrl] = useState(null);

	const goToExternalSite = (url) => {
		window.location.href = url;
	};

	const handleCheckout = async () => {
		setLoading(true);
		const stripe = await stripePromise;

		try {
			const response = await api.post(
				"/api/v1/subscription/create-checkout-session",
				{ priceId }
			);

			setUrl(response.data.url);
			setSessionId(response.data.sessionId);
			console.log(response);

			const { error } = await stripe.redirectToCheckout({
				sessionId: sessionId,
			});

			if (error) {
				console.error(error);
			}
		} catch (err) {
			console.log(err);
		} finally {
			setLoading(false);
		}
	};

	return (
		<Box sx={{ display: "flex", flexDirection: "column" }}>
			<button
				onClick={handleCheckout}
				disabled={loading}
				className="bg-blue-600 text-white px-4 py-2 rounded"
			>
				{loading ? "Redirecting..." : "Subscribe"}
			</button>
			{Url ? (
				<button onClick={() => goToExternalSite(Url)}>
					{"Checkout Through Stripe"}
				</button>
			) : null}
		</Box>
	);
};

export default CheckoutButton;
