//payroll create modal
import { Box } from '@mui/material';
import * as React from 'react';

import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuList from '@mui/joy/MenuList';
import MenuItem from '@mui/joy/MenuItem';
import Dropdown from '@mui/joy/Dropdown';
import Stack from '@mui/joy/Stack';
import Modal from '@mui/joy/Modal';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import FormLabel from '@mui/joy/FormLabel';
import FormControl from '@mui/joy/FormControl';
import { useEffect } from 'react';
import { useMediaQuery } from '@mui/material';
import Typography from '@mui/joy/Typography';
import Alert from '@mui/joy/Alert';

const SearchRateBookModal = ({
  open,
  handleClose,
  ratebook,
  setRatebook,
  selectedRate,
  setSelectedRate,
  listData,
}) => {
  const [filteredRatebook, setFilteredRatebook] = React.useState(ratebook);
  const [ratebookList, setRatebookList] = React.useState([]);
  const [classificationList, setClassificationList] = React.useState([]);
  const [craftlist, setCraftList] = React.useState([]);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const [filters, setFilters] = React.useState({
    ratebook: '',
    trade: '',
    classification: '',
  });
  const [errMsg, setErrMsg] = React.useState('');

  //useeffect to filter the ratebook list
  useEffect(() => {
    if (!listData) return;
    setRatebookList(listData?.book?.data?.data);
    setClassificationList(listData?.classification?.data?.data);
    setCraftList(listData?.craft?.data?.data);
  }, [listData]);

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: isMobile ? '400px' : '700px',
    width: '100%',
    height: '500px',
    bgcolor: 'background.body',
    border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
    overflow: 'auto',
    zIndex: 1000000,
  };
  useEffect(() => {
    if (!listData) return;
    setRatebookList(listData?.book?.data?.data);
    setClassificationList(listData?.classification?.data?.data);
    setCraftList(listData?.craft?.data?.data);
  }, [listData]);

  useEffect(() => {
    if (ratebook) {
      const filteredRatebook = ratebook.filter((item) => {
        const matchesRatebook = filters.ratebook
          ? item.book === filters.ratebook
          : true;
        const matchesTrade = filters.trade
          ? item.craft === filters.trade
          : true;
        const matchesClassification = filters.classification
          ? item.classification === filters.classification
          : true;

        return matchesRatebook && matchesTrade && matchesClassification;
      });

      setFilteredRatebook(filteredRatebook);

      if (filteredRatebook.length === 0) {
        setErrMsg(
          `No Ratebook found for Name:${filters.ratebook}, Trade: ${filters.trade}, Classification:${filters.classification}`
        );
      } else {
        setErrMsg(''); // Clear the error message when results are found
      }
    }
  }, [filters, ratebook]);

  const handleFilter = (e, type) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [type]: e,
    }));
  };

  //list of all trades
  //task 1: set  selected rate
  //task 2: filter down ratebook array
  const handleChange = (key) => (event) => {
    setRatebook({
      ...ratebook,
      [key]: event.target.value,
    });
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby='simple-modal-title'
      aria-describedby='simple-modal-description'>
      <Box sx={modalStyle}>
        <Stack
          direction={isMobile ? 'column' : 'row'}
          spacing={2}
          justifyContent={'space-between'}>
          <FormControl sx={{ width: '30%', textAlign: 'center' }}>
            <FormLabel>Ratebook Name</FormLabel>

            <MenuList
              component='div'
              variant='outlined'
              size='sm'
              sx={{
                boxShadow: 'sm',
                flexGrow: 0,
                minWidth: 200,
                maxHeight: 200,
                overflow: 'auto',
              }}>
              {ratebook.map((item) => (
                <MenuItem
                  value={item}
                  selected={filters.ratebook === item}
                  onClick={() => {
                    handleFilter(item, 'ratebook');
                  }}
                  slotProps={{
                    root: { variant: 'outlined', color: 'neutral' },
                  }}>
                  {item}
                </MenuItem>
              ))}
            </MenuList>
          </FormControl>

          <FormControl sx={{ width: '30%', textAlign: 'center' }}>
            <FormLabel>Trade</FormLabel>

            <MenuList
              component='div'
              variant='outlined'
              size='sm'
              sx={{
                boxShadow: 'sm',
                flexGrow: 0,
                minWidth: 200,
                maxHeight: 200,
                overflow: 'auto',
              }}>
              {craftlist?.map((item) => (
                <MenuItem
                  onClick={() => {
                    handleFilter(item, 'trade');
                  }}
                  selected={filters.trade === item}
                  value={item}>
                  {item}
                </MenuItem>
              ))}
            </MenuList>
          </FormControl>
          <FormControl sx={{ width: '30%', textAlign: 'center' }}>
            <FormLabel>Classification</FormLabel>

            <MenuList
              component='div'
              variant='outlined'
              size='sm'
              sx={{
                boxShadow: 'sm',
                flexGrow: 0,
                minWidth: 200,
                maxHeight: 200,
                overflow: 'auto',
              }}>
              {classificationList?.map((item) => (
                <MenuItem
                  selected={filters.classification === item}
                  onClick={() => {
                    handleFilter(item, 'classification');
                  }}
                  value={item}>
                  {item}
                </MenuItem>
              ))}
            </MenuList>
          </FormControl>
        </Stack>
        <Box>
          <Stack
            direction='column'
            spacing={2}
            justifyContent={'space-between'}>
            {filteredRatebook?.map((item) => (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '10px',
                  border: '1px solid #000',
                  borderRadius: '5px',
                  marginBottom: '10px',
                }}
                onClick={() => {
                  setSelectedRate(item);
                  handleClose();
                }}>
                <Typography>{item.book}</Typography>
                <Typography>{item.craft}</Typography>
                <Typography>{item.classification}</Typography>
              </Box>
            ))}
          </Stack>
        </Box>

        <Alert color='danger'>{errMsg}</Alert>
        <Typography variant='body' sx={{ color: 'grey' }} align='center'>
          Results: {filteredRatebook.length}
        </Typography>
      </Box>
    </Modal>
  );
};

export default SearchRateBookModal;
