//payroll create modal
import { Box, Typography } from '@mui/material';
import Modal from '@mui/material/Modal';
import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Stack } from '@mui/material';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '500px',
  width: '100%',
  height: '500px',
  bgcolor: 'common.black',
  border: '2px solid #000',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
  overflow: 'auto',
  zIndex: 1000000,
};

const PayrollCreateSettingsModal = ({
  open,
  handleClose,
  details,
  setDetails,
}) => {
  const handleChange = (key) => (event) => {
    setDetails({
      ...details,
      [key]: event.target.value,
    });
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby='simple-modal-title'
      aria-describedby='simple-modal-description'>
      <Box sx={modalStyle}>
        <Stack direction='row' spacing={2} justifyContent={'space-between'}>
          <Typography sx={{ width: '50%' }}>
            {' '}
            Apply Break Duration After Hour
          </Typography>
          <FormControl sx={{ width: '30%', textAlign: 'center' }}>
            <InputLabel id='demo-simple-select-label'>
              Break Hour Applied
            </InputLabel>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              value={details?.breakHourApplied}
              label='Break Hour Applied'
              onChange={handleChange('breakHourApplied')}>
              <MenuItem value={3}>3</MenuItem>
              <MenuItem value={4}>4</MenuItem>
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={6}>6</MenuItem>
              <MenuItem value={7}>7</MenuItem>
            </Select>
          </FormControl>
        </Stack>
        <Stack
          direction='row'
          spacing={2}
          justifyContent={'space-between'}
          sx={{ mt: 2 }}>
          <Typography> Apply Overtime Hours After</Typography>
          <FormControl sx={{ width: '30%', textAlign: 'center' }}>
            <InputLabel id='demo-simple-select-label'>
              Overtime Hour Applied
            </InputLabel>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              value={details?.overtimeHourApplied}
              label='Break Hour Applied'
              onChange={handleChange('overtimeHourApplied')}>
              <MenuItem value={8}>8</MenuItem>
              <MenuItem value={9}>9</MenuItem>
              <MenuItem value={10}>10</MenuItem>
            </Select>
          </FormControl>
        </Stack>
      </Box>
    </Modal>
  );
};

export default PayrollCreateSettingsModal;
