import { Grid, Stack, Box, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import SubscriptionCheckoutForm from "../components/SubscriptionCheckoutForm";
import { useEffect, useState } from "react";
import { Typography } from "@mui/material";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function PaymentPage({ clientSecret, onCancel }) {
	const isAboveMediumScreens = useMediaQuery("(min-width: 800px)");
	const [showModal, setShowModal] = useState(true);
	const closeModal = () => setShowModal(false);
	const theme = useTheme();
	const elementOptions = {
		clientSecret,
		appearance: {},
	};

	useEffect(() => {
		elementOptions.appearance.theme =
			theme.palette.mode === "dark" ? "night" : "stripe";
	}, [elementOptions.appearance, theme.palette.mode]);

	const handleSuccess = () => {
		closeModal();
	};

	const handleCancel = () => {
		closeModal();
		onCancel();
	};
	return (
		<Box>
			<Box sx={isAboveMediumScreens ? null : { py: "20px" }}>
				<Grid
					container
					spacing={0}
					direction="column"
					alignItems="center"
					justifyContent="center"
					style={{ minHeight: "100vh" }}
				>
					<Stack
						direction={isAboveMediumScreens ? "row" : "column"}
						alignItems="center"
						justifyContent="center"
						spacing={5}
					>
						<Grid
							item
							xs={isAboveMediumScreens ? 4 : 7}
							sx={
								isAboveMediumScreens ? { width: "305px" } : { width: "430px" }
							}
							elevation={3}
						>
							{clientSecret && (
								<Elements stripe={stripePromise} options={elementOptions}>
									<SubscriptionCheckoutForm
										onSuccess={handleSuccess}
										onCancel={handleCancel}
									/>
								</Elements>
							)}
							<Typography>Testing</Typography>
						</Grid>
					</Stack>
				</Grid>
			</Box>
		</Box>
	);
}

export default PaymentPage;
