import {
	Box,
	Button,
	Card,
	CardContent,
	CardHeader,
	CircularProgress,
	Divider,
	FormControl,
	Stack,
	useTheme,
	Typography,
} from "@mui/material";
import TopBarAdmin from "../TopBarAdmin";
import Header from "../Header";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { companySizeData } from "../../assets/signupconstants";
import { countries } from "../../assets/countries";
import { states } from "../../assets/states";
import { useAuth } from "../../context/AuthProvider";
import dayjs from "dayjs";
import { tokens } from "../../theme";

const VALIDATION_SCHEMA = Yup.object().shape({
	companyName: Yup.string().required().label("Company Name"),
});

const Settings = ({ data }) => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const [company, setCompany] = useState({
		companyName: "",
		companySize: "",
		country: "",
		state: "",
	});
	const [isProcessing, setIsProcessing] = useState(false);
	const [errState, setErrState] = useState(false);
	const [errMsg, setErrMsg] = useState("");
	console.log(data, "data");
	const { api, user, subscription } = useAuth();

	let formik = useFormik({
		initialValues: company,
		validationSchema: VALIDATION_SCHEMA,
		onSubmit: (values) => {
			setIsProcessing(true);

			api
				.put(`/api/v1/company/${company._id}`, values)
				.then(({ data }) => {
					setCompany(data.data);
				})
				.catch((err) => {
					console.log("FAIL", err);
				})
				.finally(() => {
					setIsProcessing(false);
				});
		},
	});

	const handleSubmit = async (e) => {
		e.preventDefault();
		formik.handleSubmit(formik.values);
	};

	useEffect(() => {
		if (!user) {
			setErrState(true);
			setErrMsg("User not found");
		}

		if (!subscription) {
			setErrState(true);
			setErrMsg("Subscription not found");
		}
		console.log("user", user);
		console.log("subscription", subscription);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [api, user.company.id, subscription]);

	return (
		<Card raised>
			<CardHeader title="Details"></CardHeader>
			<Divider />
			<CardContent sx={{ backgroundColor: colors.grey[700] }}>
				<FormControl sx={{ width: "100%" }}>
					<Stack>
						<Typography variant="joymed">
							{"Currency - "}
							{subscription?.currency}
						</Typography>
						<Typography variant="joymed">
							{"Subscription Created On- "}
							{dayjs.unix(subscription?.created).format("MM/DD/YYYY")}{" "}
						</Typography>
						<Typography variant="joymed">
							{"Status - "}
							{subscription?.status}
						</Typography>

						<Typography variant="joymed">
							{"Billing Start - "}{" "}
							{dayjs
								.unix(subscription?.current_period_start)
								.format("MM/DD/YYYY")}{" "}
						</Typography>
						<Typography variant="joymed">
							{"Next Billing Date - "}{" "}
							{dayjs
								.unix(subscription?.current_period_end)
								.format("MM/DD/YYYY")}{" "}
						</Typography>

						<Typography variant="joymed">
							{"Amount - $"} {subscription?.plan?.amount / 100}
						</Typography>
						<br />
						<Typography variant="joymed">Last Invoice</Typography>
						<Typography variant="joymed">
							{"Billing Email - "}
							{subscription?.customer_email}
						</Typography>
						<Typography variant="joymed">
							{"Plan Name - "}
							{subscription?.plan?.product?.name}
						</Typography>
						<Typography variant="joymed">
							{"Period Start - "}
							{dayjs
								.unix(subscription?.latest_invoice?.period_start)
								.format("MM/DD/YYYY")}
						</Typography>
						<Typography variant="joymed">
							{"Period End - "}
							{dayjs
								.unix(subscription?.latest_invoice?.period_end)
								.format("MM/DD/YYYY")}
						</Typography>

						<Typography variant="joymed">
							{"Total Users - "}
							{company?.status}
						</Typography>
						<Typography variant="joymed">
							{"Users Remaining- "}
							{subscription?.status}
						</Typography>
						{/* <Button
              onClick={handleSubmit}
              disabled={isProcessing || !formik.dirty || !formik.isValid}
              variant='contained'
              color='primary'
              type='submit'
              size='large'>
              {isProcessing ? (
                <CircularProgress size={24} color='primary' />
              ) : (
                'Update Company Settings'
              )}
            </Button> */}
					</Stack>
				</FormControl>
			</CardContent>
		</Card>
	);
};

export default Settings;
