function dashboardConfig() {
	const gridTemplateLargeScreens = `
  "a b c"
  "a b c"
  "a b c"
  "d e f"
  "d e f"
  "d e f"
  "g h i"
  "g h i"
  "g h i"

`;

	const gridTemplateMediumScreens = `
"a b"
"a b"
"a b"
"c d"
"c d"
"c d"
"e f"
"e f"
"e f"
"g h"
"g h"
"g h"
`;

	const gridTemplateAdminMediumScreens = `
"a b"
"a b"
"a b"
"c d"
"c d"
"c d"
"e f"
"e f"
"e f"
"g h"
"g h"
"g h"
"i j"
"i j"
"i j"
"i j" 
`;

	const gridTemplateSmallScreens = `
"a"
"a"
"a"
"a"
"b"
"b"
"b"
"b"
"c"
"c"
"c"
"c"
"d"
"d"
"d"
"d"
"e"
"e"
"e"
"e"
"f"
"f"
"f"
"f"
"g"
"g"
"g"
"g"
"h"
"h"
"h"
"h"
`;

	const gridTemplateAdminSmallScreens = `
  "a"
  "a"
  "a"
  "a"
  "b"
  "b"
  "b"
  "b"
  "c"
  "c"
  "c"
  "c"
  "d"
  "d"
  "d"
  "d"
  "e"
  "e"
  "e"
  "e"
  "f"
  "f"
  "f"
  "f"
  "g"
  "g"
  "g"
  "g"
  "h"
  "h"
  "h"
  "h"
  "i"
  "i"
  "i"
  "i"

`;

	return {
		gridTemplateLargeScreens,
		gridTemplateMediumScreens,
		gridTemplateSmallScreens,
		gridTemplateAdminMediumScreens,
		gridTemplateAdminSmallScreens,
	};
}

export default dashboardConfig;
