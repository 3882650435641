import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

const SuccessPage = () => {
	const [searchParams] = useSearchParams();
	const sessionId = searchParams.get("session_id");
	const [loading, setLoading] = useState(true);
	const [paymentStatus, setPaymentStatus] = useState(null);

	useEffect(() => {
		if (sessionId) {
			fetch(`/api/stripe/session/${sessionId}`)
				.then((res) => res.json())
				.then((data) => {
					setPaymentStatus(data.status);
					setLoading(false);
				})
				.catch(() => setPaymentStatus("error"));
		}
	}, [sessionId]);

	if (loading) return <p>Loading...</p>;

	return (
		<div className="flex flex-col items-center justify-center h-screen">
			{paymentStatus === "complete" ? (
				<>
					<h1 className="text-2xl font-bold text-green-600">
						Payment Successful!
					</h1>
					<p>Your subscription has been activated.</p>
					<a href="/" className="text-blue-600 underline mt-4">
						Go to Dashboard
					</a>
				</>
			) : (
				<>
					<h1 className="text-2xl font-bold text-red-600">Payment Failed</h1>
					<p>We couldn't verify your payment. Please try again.</p>
					<a href="/" className="text-blue-600 underline mt-4">
						Try Again
					</a>
				</>
			)}
		</div>
	);
};

export default SuccessPage;
