import { Box, Grid } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Row1 from "../dashboard3/Row1";
import Row2 from "../dashboard3/Row2";
import Row3 from "../dashboard3/Row3";
import dashboardConfig from "../fakedata/dashboardConfig";
import useFetch from "../hooks/fetchAPI";
import threeWeekLookBack from "../hooks/three-week-lookback";
import filteredBarGraphData from "../hooks/fx-create-bar-graph-array";
import { useEffect, useState } from "react";
import LoadingCircle from "../components/LoadingCircle";
import dayjs from "dayjs";
import { useAuth } from "../context/AuthProvider";
import TopBarDashboardSettings from "../components/TopBarDashboardSettings";

var weekOfYear = require("dayjs/plugin/weekOfYear");
dayjs.extend(weekOfYear);

const Dashboard3 = () => {
	const {
		gridTemplateLargeScreens,
		gridTemplateMediumScreens,
		gridTemplateSmallScreens,
		gridTemplateAdminMediumScreens,
		gridTemplateAdminSmallScreens,
	} = dashboardConfig();
	const { sixWkBkDesc } = threeWeekLookBack();
	const { api, user } = useAuth();
	const isLargeScreen = useMediaQuery("(min-width: 1200px)");
	const isMediumScreen = useMediaQuery(
		"(min-width:768px) and (max-width:1199px)"
	);
	const isAdmin = user.role === "Company Admin" ? true : false;

	const gridStyles = isLargeScreen
		? {
				gridTemplateColumns: "repeat(3, minmax(330px, 1fr))",
				gridTemplateRows: "repeat(10, minmax(30px, 1fr))",
				gridTemplateAreas: gridTemplateLargeScreens,
				padding: "1rem",
		  }
		: isMediumScreen
		? {
				gridTemplateColumns: "repeat(2, minmax(330px, 1fr))",
				gridTemplateRows: "repeat(15, minmax(30px, 1fr))",
				gridTemplateAreas: isAdmin
					? gridTemplateAdminMediumScreens
					: gridTemplateMediumScreens,
				padding: "2rem",
		  }
		: {
				gridAutoColumns: "1fr",
				gridAutoRows: "55px",
				gridTemplateAreas: isAdmin
					? gridTemplateAdminSmallScreens
					: gridTemplateSmallScreens,
				padding: "4rem",
		  };

	const PrevWeekNumber = dayjs().day(-1).week();

	const [progress, setProgress] = useState(10);
	const [isLoaded, setisLoaded] = useState(false);
	const [listData, setListData] = useState([]);
	const [changeOrderData, setChangeOrderData] = useState([]);
	const [rfiData, setRfiData] = useState([]);
	const [swData, setSwData] = useState([]);
	const [payrollData, setPayrollData] = useState([]);
	const [TimecardRange, setTimecardRange] = useState([]);
	const isoWeek = require("dayjs/plugin/isoWeek");
	dayjs.extend(isoWeek);
	const lastWeekend = dayjs().day() === 6 ? dayjs() : dayjs().day(6 - 7);
	const [searchFilters, setSearchFilters] = useState({
		approved: false,
		status: "Incomplete",
		project: "All Projects",
		weekNumberStart: dayjs().subtract(4, "week").isoWeek(),
		weekStartString: dayjs().subtract(4, "week").day(6).format("MM/DD/YYYY"),
		yearStart: dayjs().subtract(4, "week").year(),
		weekNumberEnd: lastWeekend.isoWeek(),
		weekEndString: lastWeekend.day(6).format("MM/DD/YYYY"),
		yearEnd: lastWeekend.year(),
	});

	const [payrollLoaded, setPayrollLoaded] = useState(false);
	const [searchPayrollFilters, setSearchPayrollFilters] = useState({
		paid: true,
		weekNumberStart: null,
		yearStart: null,
		weekNumberEnd: null,
		yearEnd: null,
	});
	const [monthlySearchFilters, setMonthlySearchFilters] = useState({
		startYear: dayjs().subtract(1, "year").year(),
		startMonth: dayjs().subtract(11, "month").month() + 1,
		endYear: dayjs().year(),
		endMonth: dayjs().month() + 1,
	});
	console.log(monthlySearchFilters);

	const [professionalSearchFilters, setProfessionalSearchFilters] = useState({
		project: "All Projects",
		role: "All Trades",
		classification: "All Classes",
		approved: false,
	});
	console.log(professionalSearchFilters);
	console.log(monthlySearchFilters);
	const [timecards, setTimecards] = useState([]);
	const [pagination, setPagination] = useState({
		page: 1,
		limit: 20,
		totaldocuments: 0,
	});
	const [error, setError] = useState({
		timecards: false,
		rfi: false,
		co: false,
		sw: false,
		payroll: false,
		projectlist: false,
		craftlist: false,
		classificationlist: false,
	});
	const [errMsg, seterrMsg] = useState({
		timecards: "",
		rfi: "",
		co: "",
		sw: "",
	});

	const weeksObject = threeWeekLookBack();

	useEffect(() => {
		setSearchPayrollFilters({
			paid: true,
			weekNumberStart: dayjs().subtract(7, "week").week(),
			yearStart: dayjs().subtract(7, "week").year(),
			weekNumberEnd: dayjs().week(),
			yearEnd: dayjs().year(),
		});
		setPayrollLoaded(true);
	}, []);

	useEffect(() => {
		let timer;
		if (progress < 100) {
			timer = setInterval(() => {
				setProgress((prevProgress) =>
					prevProgress >= 100 ? 0 : prevProgress + 10
				);
			}, 100);
		}

		if (progress === 100) {
			setisLoaded(true);
		}

		return () => {
			clearInterval(timer);
		};
	}, [progress]);

	function checkFailed(then) {
		return function (responses) {
			const someFailed = responses.some((response) => response.error);

			if (someFailed) {
				throw responses;
			}

			return then(responses);
		};
	}

	useEffect(() => {
		async function fetchMultipleDataStreams(...prevPayroll) {
			await Promise.all([
				api.get(
					`/api/v1/rfi/?page=1&limit=20&project=${professionalSearchFilters.project}`
				),
				api.get(
					`/api/v1/changeorder/?page=1&limit=20&project=${professionalSearchFilters.project}`
				),
				api.get("/api/v1/projects/list"),
				api.get("/api/v1/payroll/weekly"),
				api.get("/api/v1/ratebook/craftlist"),
				api.get("/api/v1/ratebook/classificationlist"),
			])
				.then(
					checkFailed(
						([
							rfi,
							co,
							projectList,
							payroll,
							craftlist,
							classificationlist,
						]) => {
							setListData((prev) => ({
								...prev,
								rfi,
								co,
								projectList,
								payroll,
								craftlist,
								classificationlist,
							}));
						}
					)
				)
				.catch((err) => {
					console.log("FAIL", err);
				});
		}

		if (api.authenticated) {
			fetchMultipleDataStreams();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [api?.authenticated]);

	useEffect(() => {
		//setIsLoading(true);
		let renderedData = [];

		if (
			api.authenticated &&
			payrollLoaded === true &&
			searchPayrollFilters.yearStart !== NaN
		) {
			api

				.get(
					`/api/v1/payroll/weekly?page=1&limit=20&paid=${searchPayrollFilters.paid}&project=${professionalSearchFilters.project}&trade=${professionalSearchFilters.role}&classification=${professionalSearchFilters.classification}&weekNumberStart=${searchPayrollFilters.weekNumberStart}&yearStart=${searchPayrollFilters.yearStart}&weekNumberEnd=${searchPayrollFilters.weekNumberEnd}&yearEnd=${searchPayrollFilters.yearEnd}`
				)
				.then((response) => {
					setPayrollData(response.data.data);
				})
				.catch((err) => {
					console.log(err);
					setPayrollData([]);
				})
				.finally(() => {
					//setIsLoading(false);
					// setTimecards(renderedData);
					if (renderedData.length === 0) {
						setError(true);
						seterrMsg("No Payroll Found");
						setPagination((prev) => ({
							...prev,
							page: 1,
							totaldocuments: 0,
						}));
					}
				});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [api, searchPayrollFilters, payrollLoaded]);

	//const result = filteredBarGraphData(sixWkBkDesc, data);

	//useEffect for change in search filters
	useEffect(() => {
		//setisLoaded(false);
		let renderedData = [];

		if (api.authenticated) {
			api

				.get(
					`/api/v1/timecardsv3/mytimecards/?page=1&limit=20&approved=${searchFilters.approved}&status=${searchFilters.status}&project=${searchFilters.project}&weekNumberStart=${searchFilters.weekNumberStart}&yearStart=${searchFilters.yearStart}&weekNumberEnd=${searchFilters.weekNumberEnd}&yearEnd=${searchFilters.yearEnd}`
				)
				.then((response) => {
					setTimecards(response.data.data[0].results);
				})
				.catch((err) => {
					console.log(err);
					setTimecards([]);
					setPagination((prev) => ({
						...prev,
						page: 1,
						totaldocuments: 0,
					}));
				})
				.finally(() => {
					setisLoaded(true);

					if (renderedData.length === 0) {
						setError((prev) => ({
							...prev,
							timecards: true,
						}));
						seterrMsg((prev) => ({
							...prev,
							timecards: "No timecards found",
						}));
						setPagination((prev) => ({
							...prev,
							page: 1,
							totaldocuments: 0,
						}));
					}
				});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [api, searchFilters]);

	//useEffect for change in search filters
	useEffect(() => {
		//setisLoaded(false);
		let renderedCOData = [];

		if (api.authenticated) {
			api

				.get(
					`/api/v1/changeorder/?page=1&limit=20&project=${professionalSearchFilters.project}`
				)
				.then((response) => {
					setChangeOrderData(response.data.data[0].results);
				})
				.catch((err) => {
					console.log(err);
				})
				.finally(() => {
					setisLoaded(true);

					if (renderedCOData.length === 0) {
						setError((prev) => ({
							...prev,
							co: true,
						}));
						seterrMsg((prev) => ({
							...prev,
							co: "No change orders found",
						}));
					}
				});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [api]);

	//useEffect for change in search filters
	useEffect(() => {
		//setisLoaded(false);
		let renderedRFIData = [];
		setError((prev) => ({
			...prev,
			rfi: false,
		}));
		seterrMsg((prev) => ({
			...prev,
			rfi: "",
		}));
		if (api.authenticated) {
			api

				.get(
					`/api/v1/rfi/?page=1&limit=20&project=${professionalSearchFilters.project}`
				)
				.then((response) => {
					setRfiData(response.data.data[0].results);
				})
				.catch((err) => {
					console.log(err);
				})
				.finally(() => {
					setisLoaded(true);

					if (renderedRFIData.length === 0) {
						setError((prev) => ({
							...prev,
							rfi: true,
						}));
						seterrMsg((prev) => ({
							...prev,
							rfi: "No rfis found",
						}));
					}
				});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [api]);

	//useEffect for change in search filters
	useEffect(() => {
		//setisLoaded(false);
		let renderedSWData = [];
		setError((prev) => ({
			...prev,
			sw: false,
		}));
		seterrMsg((prev) => ({
			...prev,
			sw: "",
		}));
		if (api.authenticated) {
			api
				.get(
					`/api/v1/timecardsv3/sandw/?page=1&limit=20&startYear=${monthlySearchFilters.startYear}&startMonth=${monthlySearchFilters.startMonth}&endYear=${monthlySearchFilters.endYear}&endMonth=${monthlySearchFilters.endMonth}&project=${professionalSearchFilters.project}&approved=false`
				)
				.then((response) => {
					renderedSWData = response.data.data.map((sw, i) => ({
						id: sw._id.month + "/" + sw._id.year,
						month: sw._id.month,
						year: sw._id.year,
						key: sw._id.month + "/" + sw._id.year,
						trade: sw._id.trade,
						reg: sw.regHours,
						ot: sw.overtimeHours,
						sat: sw.satHours,
						sun: sw.sunHours,
						totalHours: sw.totalHours,
					}));
				})
				.catch((err) => {
					console.log(err);
				})
				.finally(() => {
					setisLoaded(true);
					setSwData(renderedSWData);
					if (renderedSWData.length === 0) {
						setError((prev) => ({
							...prev,
							sw: true,
						}));
						seterrMsg((prev) => ({
							...prev,
							sw: "No S&W Data found",
						}));
					}
				});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [api, monthlySearchFilters, professionalSearchFilters]);

	return (
		<Box width="100%">
			{isLoaded ? (
				<Box>
					<TopBarDashboardSettings
						monthlySearchFilters={monthlySearchFilters}
						setMonthlySearchFilters={setMonthlySearchFilters}
						professionalSearchFilters={professionalSearchFilters}
						setProfessionalSearchFilters={setProfessionalSearchFilters}
						listData={listData}
					/>
					<Box width="100%" display="grid" gap="1.5rem" sx={gridStyles}>
						<Row1
							timecardData={timecards}
							error={error.timecards}
							errMsg={errMsg.timecards}
							label={PrevWeekNumber}
							swData={swData}
							professionalSearchFilters={professionalSearchFilters}
						/>
						<Row2
							listData={listData}
							swData={swData}
							error={error}
							errMsg={errMsg}
							monthlySearchFilters={monthlySearchFilters}
							professionalSearchFilters={professionalSearchFilters}
						/>
						<Row3
							changeOrderData={changeOrderData}
							rfiData={rfiData}
							listData={listData}
							error={error}
							errMsg={errMsg}
							user={user}
							payrollData={payrollData}
							searchPayrollFilters={searchPayrollFilters}
							setSearchPayrollFilters={setSearchPayrollFilters}
						/>
					</Box>
				</Box>
			) : (
				<Grid
					container
					spacing={0}
					direction="column"
					alignItems="center"
					justifyContent="center"
				>
					<Grid item xs={3} elevation={3}>
						<LoadingCircle
							progress={progress}
							setProgress={setProgress}
							isLoaded={isLoaded}
							setisLoaded={setisLoaded}
						/>
					</Grid>
				</Grid>
			)}
		</Box>
	);
};

export default Dashboard3;
