const CancelPage = () => {
	return (
		<div className="flex flex-col items-center justify-center h-screen">
			<h1 className="text-2xl font-bold text-red-600">Payment Canceled</h1>
			<p>Your payment was not processed. You can try again.</p>
			<a href="/" className="text-blue-600 underline mt-4">
				Go back to checkout
			</a>
		</div>
	);
};

export default CancelPage;
