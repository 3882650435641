import { useState, useEffect, useMemo } from "react";
import TopBarAdmin from "../components/TopBarAdmin";
import TimecardFilters from "../components/TimecardFilters"; // src/components/
import { Box, Grid, Typography, useTheme, Stack } from "@mui/material";
import { useAuth } from "../context/AuthProvider";
import { tokens } from "../theme";
import useMediaQuery from "@mui/material/useMediaQuery";
import dayjs from "dayjs";
import TimecardCard from "../components/TimecardCard";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

function MobileViewPage() {
	const isoWeek = require("dayjs/plugin/isoWeek");
	dayjs.extend(isoWeek);
	const [notificationsNumber, setNotificationsNumber] = useState(0);
	const { api } = useAuth();
	const [timecards, setTimecards] = useState([]);
	const lastWeekend = dayjs().day() === 6 ? dayjs() : dayjs().day(6 - 7);
	const [searchFilters, setSearchFilters] = useState({
		approved: false,
		status: "Incomplete",
		project: "All Projects",
		weekNumberStart: dayjs().subtract(4, "week").isoWeek(),
		weekStartString: dayjs().subtract(4, "week").day(6).format("MM/DD/YYYY"),
		yearStart: dayjs().subtract(4, "week").year(),
		weekNumberEnd: lastWeekend.isoWeek(),
		weekEndString: lastWeekend.day(6).format("MM/DD/YYYY"),
		yearEnd: lastWeekend.year(),
	});
	console.log("searchFilters", searchFilters);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);
	const [listData, setListData] = useState(null);
	const [errMsg, seterrMsg] = useState("");
	const [pagination, setPagination] = useState({
		page: 1,
		limit: 20,
		totaldocuments: 0,
	});

	const [state, setState] = useState({
		open: false,
		vertical: "top",
		horizontal: "center",
	});
	const { vertical, horizontal, open } = state;

	const handleClick = (newState) => () => {
		setState({ ...newState, open: true });
	};

	const handleClose = () => {
		setState({ ...state, open: false });
	};

	//themes
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

	// Function to generate an array of week-ending Saturdays for the past 26 weeks
	function getWeekEndingSaturdays() {
		const saturdays = [];
		const today = dayjs();
		// Find the last Saturday
		const lastSaturday = today.day() === 6 ? today : today.day(6 - 7);

		// Generate 26 Saturdays with ISO week numbers
		for (let i = 0; i < 26; i++) {
			const date = lastSaturday.subtract(i * 7, "days");
			saturdays.push({
				weekEnd: date.format("MM/DD/YYYY"),
				weekNumber: date.isoWeek(),
				year: date.year(),
			});
		}

		return saturdays.reverse(); // Reverse to get ascending order
	}

	// Call the function and log the results
	const weekEndingSaturdays = getWeekEndingSaturdays();
	console.log(weekEndingSaturdays);
	//useEffect for change in search filters
	useEffect(() => {
		setIsLoading(true);
		let renderedData = [];

		if (api.authenticated) {
			api
				.get(
					`/api/v1/timecardsv3/mytimecards/?page=1&limit=20&approved=${searchFilters.approved}&status=${searchFilters.status}&project=${searchFilters.project}&weekNumberStart=${searchFilters.weekNumberStart}&yearStart=${searchFilters.yearStart}&weekNumberEnd=${searchFilters.weekNumberEnd}&yearEnd=${searchFilters.yearEnd}`
				)
				.then((response) => {
					console.log(response);
					if (response?.data?.data[0]?.results) {
						const formatDate = (date) =>
							date ? dayjs(date).format("MM/DD/YYYY") : null;
						const formatDay = (date) => (date ? dayjs(date) : null);

						renderedData = response?.data?.data[0].results.map((timecard) => {
							const formattedTimecard = {
								...timecard,
								weekEndingString: formatDate(timecard.weekEnding),
								createdAt: formatDate(timecard.createdAt),
								updatedAt: formatDate(timecard.updatedAt),
							};

							const periods = ["p1", "p2", "p3"];
							const days = ["sun", "mon", "tue", "wed", "thur", "fri", "sat"];

							periods.forEach((period) => {
								days.forEach((day) => {
									formattedTimecard[`${day}${period}start`] = formatDay(
										timecard[`${day}${period}start`]
									);
									formattedTimecard[`${day}${period}end`] = formatDay(
										timecard[`${day}${period}end`]
									);
								});
							});

							return formattedTimecard;
						});

						setPagination((prev) => ({
							...prev,
							page: response.data.data[0].page,
							limit: response.data.data[0].limit,
							totaldocuments: response.data.data[0].totaldocuments,
						}));
					}
				})
				.catch((err) => {
					console.log(err);
					setTimecards([]);
					setPagination((prev) => ({
						...prev,
						page: 1,
						totaldocuments: 0,
					}));
				})
				.finally(() => {
					setIsLoading(false);
					setTimecards(renderedData);
					if (renderedData.length === 0) {
						setError(true);
						seterrMsg("No Timecards Found");
						setPagination((prev) => ({
							...prev,
							page: 1,
							totaldocuments: 0,
						}));
					}
				});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [api, searchFilters]);

	function checkFailed(then) {
		return function (responses) {
			const someFailed = responses.some((response) => response?.error);

			if (someFailed) {
				throw responses;
			}

			return then(responses);
		};
	}

	useEffect(() => {
		setIsLoading(true);
		if (api.authenticated) {
			Promise.all([
				api.get("/api/v1/personnel?sort=fullName&limit=1000"),
				api.get("/api/v1/projects/list"),
			])
				.then(
					checkFailed(([personnel, projects]) => {
						setListData((prev) => ({
							...prev,
							personnel,
							projects,
						}));
					})
				)
				.catch((err) => {
					console.log("FAIL", err);
					setError(true);
					seterrMsg("Error Loading Data");
				})
				.finally(() => {
					setIsLoading(false);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [api]);

	return (
		<Box sx={{ width: "100%", minWidth: "390px" }}>
			<TopBarAdmin notificationsNumber={notificationsNumber} />
			<Box sx={{ display: "flex", justifyContent: "center" }}>
				<Typography variant={"body2"} color="grey">
					{" "}
					My Timecards{" "}
				</Typography>
			</Box>
			<TimecardFilters
				searchFilters={searchFilters}
				setSearchFilters={setSearchFilters}
				listData={listData}
				weekEndingSaturdays={weekEndingSaturdays}
			/>
			<Box sx={{ display: "flex", justifyContent: "center" }}>
				<Typography variant={"body2"} color="grey">
					{" "}
					Results: {pagination.totaldocuments}{" "}
				</Typography>
			</Box>
			<Box sx={{ display: "flex", justifyContent: "center" }}>
				<Grid
					container
					spacing={0}
					direction="column"
					alignItems="center"
					sx={{ minHeight: "100vh" }}
				>
					<Grid item xs={3}>
						<Box
							sx={{
								width: isSmallScreen ? "98%" : "80%",
								maxWidth: isSmallScreen ? "98%" : "550px",
								minWidth: isSmallScreen ? "98%" : "490px",
								m: 0,
								pl: isSmallScreen ? 1 : 9,
								justifyContent: "center",
							}}
						>
							<Stack spacing={1} direction="column">
								{timecards?.length > 0 &&
									timecards.map((timecard, index) => (
										<TimecardCard
											key={index}
											category={timecard.status}
											title={timecard.fullName}
											rareFind={timecard.paid}
											liked={timecard.paid}
											regHours={timecard.calculations.regHours}
											overtimeHours={timecard.calculations.overtimeHours}
											satHours={timecard.calculations.satHours}
											sunHours={timecard.calculations.sunHours}
											approved={timecard.approved}
											fullName={timecard.fullName}
											firstName={timecard.firstName}
											lastName={timecard.lastName}
											status={timecard.status}
											weekEnd={timecard.weekEndingString}
											image={
												"https://images.unsplash.com/photo-1537726235470-8504e3beef77?auto=format&fit=crop&w=400"
											}
											timecard={timecard}
											listData={listData}
											handleSnackbar={handleClick}
											timecards={timecards}
											setTimecards={setTimecards}
										/>
									))}
							</Stack>
							<Box sx={{ display: "flex", justifyContent: "center" }}>
								<Snackbar
									open={open}
									anchorOrigin={{ vertical, horizontal }}
									key={vertical + horizontal}
									autoHideDuration={6000}
									onClose={handleClose}
								>
									<Alert
										onClose={handleClose}
										severity="success"
										variant="filled"
										sx={{
											width: "100%",
											color: "white",
										}}
									>
										Timecard Approved
									</Alert>
								</Snackbar>
							</Box>
						</Box>
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
}

export default MobileViewPage;
