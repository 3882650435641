import { Card, Modal, useTheme } from "@mui/material";
import SignUpForm from "./SignUpForm";
import { tokens } from "../theme";

const SignUpModal = ({ open, onCancel }) => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	const style = {
		position: "absolute",
		top: "100px",
		left: "50%",
		transform: "translateX(-50%)",
		width: 500,
		boxShadow: 24,
		backgroundColor: colors.primary[600],
		p: 6,
	};

	const handleCancel = () => {
		onCancel();
	};

	return (
		<Modal open={open} onClose={handleCancel}>
			<Card
				raised
				id="checkout"
				sx={{ ...style, flexDirection: "col", justifyContent: "space-between" }}
			>
				<SignUpForm />
			</Card>
		</Modal>
	);
};

export default SignUpModal;
