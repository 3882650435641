import { Box, Typography, useTheme, Stack } from '@mui/material';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridFooter,
  GridFooterContainer,
} from '@mui/x-data-grid';
import { tokens } from '../theme';
import Header from '../components/Header';
import PostAddIcon from '@mui/icons-material/PostAdd';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Button from '@mui/material/Button';
import QryPopOut from '../components/QryPopOut';
import dayjs from 'dayjs';
import Modal from '@mui/material/Modal';
import { useState, useEffect } from 'react';
import PayrollCreate2 from '../components/PayrollCreate2';
import ViewPayrollPopOver from '../components/ViewPayrollPopOver';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import axios from 'axios';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import { List, ListItem } from '@mui/material';
//utils
import { getRandomInt } from '../utils/createObjectsInArray';
//hooks
import usePath from '../hooks/fetchPath';
import { useNavigate } from 'react-router-dom';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import EditPayrollPopOver from '../components/EditPayrollPopOver';
import { useAuth } from '../context/AuthProvider';

const Timecard = ({
  data,
  editPayrollById,
  project,
  setProject,
  startQry,
  setStartQry,
  endQry,
  setEndQry,
  setErrMsg,
  pageState,
  setPageState,
  editMode,
  setEditMode,
  user,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { api, success } = useAuth();
  //payroll Create Modal Open State
  const [open, setOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [showPayrollView, setShowPayrollView] = useState(false);
  const [showPayrollEdit, setShowPayrollEdit] = useState(false);
  const [popOverDetails, setpopOverDetails] = useState('Hi');
  const [popOverEditDetails, setpopOverEditDetails] = useState({});
  const [selectedDataID, setselectedDataID] = useState([]);
  const handleClose = () => setOpen(false);
  const [allTimecards, setAllTimecards] = useState([]);
  const { fullPath, baseURL } = usePath('/api/v1/timecardsv2/approve/');
  const [anchorElViewPayrollPopOut, setanchorElViewPayrollPopOut] =
    useState(null);
  const openViewPayrollPopOut = Boolean(anchorElViewPayrollPopOut);
  const idViewPayrollPopOut = openViewPayrollPopOut ? 'Pop Out' : undefined;
  //modal
  const [anchorElEditPayrollPopOut, setanchorElEditPayrollPopOut] =
    useState(null);
  const openEditPayrollPopOut = Boolean(anchorElEditPayrollPopOut);
  const idEditPayrollPopOut = openEditPayrollPopOut ? 'Pop Out' : undefined;
  const [errState, setErrState] = useState(false);
  const [errMsg, seterrMsg] = useState('');

  useEffect(() => {
    if (
      user.role === 'Admin' ||
      user.role === 'Company Admin' ||
      user.role === 'Project Manager'
    ) {
      setIsAdmin(true);
    }
    let renderedData;
    //map through array of objectsw
    if (pageState?.data) {
      renderedData = pageState.data.map((timecard) => ({
        id: timecard._id,
        approved: timecard.approved,
        weekEnd: dayjs(timecard.weekEnd).format('MM-DD-YYYY'),
        regularHours: timecard.regHours,
        OTHours: timecard.overtimeHours,
        saturdayHours: timecard.satHours,
        sundayHours: timecard.sunHours,
        project: timecard.project,
        firstName: timecard.firstName,
        lastName: timecard.lastName,
        sunday: timecard.sunday,
        monday: timecard.monday,
        mondayHours: timecard.monday[0].hours[0].regDayTotal,
        monOTHours: timecard.monday[0].hours[0].OTDayTotal,
        tuesday: timecard.tuesday,
        tuesdayHours: timecard.tuesday[0].hours[0].regDayTotal,
        tueOTHours: timecard.tuesday[0].hours[0].OTDayTotal,
        wednesday: timecard.wednesday,
        wednesdayHours: timecard.wednesday[0].hours[0].regDayTotal,
        wedOTHours: timecard.wednesday[0].hours[0].OTDayTotal,
        thursday: timecard.thursday,
        thursdayHours: timecard.thursday[0].hours[0].regDayTotal,
        thurOTHours: timecard.thursday[0].hours[0].OTDayTotal,
        friday: timecard.friday,
        fridayHours: timecard.friday[0].hours[0].regDayTotal,
        friOTHours: timecard.friday[0].hours[0].OTDayTotal,
        saturday: timecard.saturday,
        paid: timecard.paid,
        updatedAt: timecard.updatedAt,
        createdAt: timecard.createdAt,
        analytics: timecard.analytics,
      }));
    }

    setAllTimecards(renderedData);
  }, [pageState, user]);

  const columns = [
    { field: 'firstName', headerName: 'First Name', width: 75 },
    { field: 'lastName', headerName: 'Last Name', width: 75 },
    {
      field: 'project',
      headerName: 'Project',
      width: 75,
      renderCell: ({ row: { project, weekEnd, id } }) => {
        return (
          <List sx={{ alignItems: 'left' }}>
            {project.map((elem, i) => {
              return (
                <ListItem sx={{ p: 0, alignItems: 'left' }}>
                  {' '}
                  {elem.project}{' '}
                </ListItem>
              );
            })}
          </List>
        );
      },
    },
    {
      field: 'approved',
      headerName: 'Approved',
      width: 60,
      renderCell: ({ row: { approved } }, i) => {
        return (
          <Box
            m='0 auto'
            p='5px'
            display='flex'
            justifyContent='center'
            backgroundColor={
              approved === true
                ? colors.greenAccent[600]
                : colors.redAccent[700]
            }
            borderRadius='4px'>
            {approved === true && (
              <CheckCircleOutlineIcon key={'Check' + getRandomInt(0, 1000)} />
            )}
            {approved === false && (
              <DoNotDisturbIcon key={'DND' + getRandomInt(1001, 2000)} />
            )}
          </Box>
        );
      },
    },
    {
      field: 'regularHours',
      headerName: ' Reg',
      width: 10,
      renderCell: ({ row: { regularHours } }) => {
        return (
          <Typography
            sx={{
              color:
                regularHours > 0 ? colors.blueAccent[400] : colors.grey[300],
              fontWeight: regularHours > 0 ? 'bold' : 'regular',
            }}>
            {regularHours}
          </Typography>
        );
      },
    },
    {
      field: 'OTHours',
      headerName: 'OT',
      width: 10,
      renderCell: ({ row: { OTHours } }) => {
        return (
          <Typography
            sx={{
              color: OTHours > 0 ? colors.blueAccent[400] : colors.grey[300],
              fontWeight: OTHours > 0 ? 'bold' : 'regular',
            }}>
            {OTHours}
          </Typography>
        );
      },
    },
    {
      field: 'saturdayHours',
      headerName: 'Sat',
      width: 10,
      renderCell: ({ row: { saturdayHours } }) => {
        return (
          <Typography
            sx={{
              color:
                saturdayHours > 0 ? colors.blueAccent[400] : colors.grey[300],
              fontWeight: saturdayHours > 0 ? 'bold' : 'regular',
            }}>
            {saturdayHours}
          </Typography>
        );
      },
    },
    {
      field: 'sundayHours',
      headerName: 'Sun',
      width: 10,
      renderCell: ({ row: { sundayHours } }) => {
        return (
          <Typography
            sx={{
              color:
                sundayHours > 0 ? colors.blueAccent[400] : colors.grey[300],
              fontWeight: sundayHours > 0 ? 'bold' : 'regular',
            }}>
            {sundayHours}
          </Typography>
        );
      },
    },
    {
      field: 'weekEnd',
      headerName: 'Week End',
      width: 90,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 60,
      renderCell: (row) => {
        return (
          <Stack>
            <VisibilityIcon
              key={'View' + getRandomInt(2001, 3000)}
              onClick={(e) => {
                handlePopOutClickViewPayroll(e, { row });
              }}
            />
            <EditIcon
              key={'Edit' + getRandomInt(3001, 4000)}
              onClick={(e) => {
                handlePopOutClickEditPayroll(e, { row });
              }}
            />
          </Stack>
        );
      },
    },
    {
      field: 'mondayHours',
      headerName: 'M',
      width: 10,
      renderCell: ({ row: { mondayHours } }) => {
        return (
          <Typography
            sx={{
              color:
                mondayHours > 0 ? colors.greenAccent[400] : colors.grey[300],
              fontWeight: mondayHours > 0 ? 'bold' : 'regular',
            }}>
            {mondayHours}
          </Typography>
        );
      },
    },
    {
      field: 'monOTHours',
      headerName: 'OT',
      width: 10,
      renderCell: ({ row: { monOTHours } }) => {
        return (
          <Typography
            sx={{
              color:
                monOTHours > 0 ? colors.greenAccent[400] : colors.grey[300],
              fontWeight: monOTHours > 0 ? 'bold' : 'regular',
            }}>
            {monOTHours}
          </Typography>
        );
      },
    },
    {
      field: 'tuesdayHours',
      headerName: 'T',
      width: 10,
      renderCell: ({ row: { tuesdayHours } }) => {
        return (
          <Typography
            sx={{
              color:
                tuesdayHours > 0 ? colors.greenAccent[400] : colors.grey[300],
              fontWeight: tuesdayHours > 0 ? 'bold' : 'regular',
            }}>
            {tuesdayHours}
          </Typography>
        );
      },
    },
    {
      field: 'tueOTHours',
      headerName: 'OT',
      width: 10,
      renderCell: ({ row: { tueOTHours } }) => {
        return (
          <Typography
            sx={{
              color:
                tueOTHours > 0 ? colors.greenAccent[400] : colors.grey[300],
              fontWeight: tueOTHours > 0 ? 'bold' : 'regular',
            }}>
            {tueOTHours}
          </Typography>
        );
      },
    },
    {
      field: 'wednesdayHours',
      headerName: 'W',
      width: 10,
      renderCell: ({ row: { wednesdayHours } }) => {
        return (
          <Typography
            sx={{
              color:
                wednesdayHours > 0 ? colors.greenAccent[400] : colors.grey[300],
              fontWeight: wednesdayHours > 0 ? 'bold' : 'regular',
            }}>
            {wednesdayHours}
          </Typography>
        );
      },
    },
    {
      field: 'wedOTHours',
      headerName: 'OT',
      width: 10,
      renderCell: ({ row: { wedOTHours } }) => {
        return (
          <Typography
            sx={{
              color:
                wedOTHours > 0 ? colors.greenAccent[400] : colors.grey[300],
              fontWeight: wedOTHours > 0 ? 'bold' : 'regular',
            }}>
            {wedOTHours}
          </Typography>
        );
      },
    },
    {
      field: 'thursdayHours',
      headerName: 'R',
      width: 10,
      renderCell: ({ row: { thursdayHours } }) => {
        return (
          <Typography
            sx={{
              color:
                thursdayHours > 0 ? colors.greenAccent[400] : colors.grey[300],
              fontWeight: thursdayHours > 0 ? 'bold' : 'regular',
            }}>
            {thursdayHours}
          </Typography>
        );
      },
    },
    {
      field: 'thurOTHours',
      headerName: 'OT',
      width: 10,
      renderCell: ({ row: { thurOTHours } }) => {
        return (
          <Typography
            sx={{
              color:
                thurOTHours > 0 ? colors.greenAccent[400] : colors.grey[300],
              fontWeight: thurOTHours > 0 ? 'bold' : 'regular',
            }}>
            {thurOTHours}
          </Typography>
        );
      },
    },
    {
      field: 'fridayHours',
      headerName: 'F',
      width: 10,
      renderCell: ({ row: { fridayHours } }) => {
        return (
          <Typography
            sx={{
              color:
                fridayHours > 0 ? colors.greenAccent[400] : colors.grey[300],
              fontWeight: fridayHours > 0 ? 'bold' : 'regular',
            }}>
            {fridayHours}
          </Typography>
        );
      },
    },
    {
      field: 'friOTHours',
      headerName: 'OT',
      width: 10,
      renderCell: ({ row: { friOTHours } }) => {
        return (
          <Typography
            sx={{
              color:
                friOTHours > 0 ? colors.greenAccent[400] : colors.grey[300],
              fontWeight: friOTHours > 0 ? 'bold' : 'regular',
            }}>
            {friOTHours}
          </Typography>
        );
      },
    },

    {
      field: 'updatedAt',
      headerName: 'Updated At',
      width: 80,
      renderCell: ({ row: { updatedAt } }, i) => {
        return (
          <Stack>
            <Typography
              display='flex'
              sx={{ fontSize: 12 }}
              justifyContent='center'>
              {dayjs(updatedAt).format('MM-DD-YY')}
            </Typography>
            <Typography
              display='flex'
              sx={{ fontSize: 12, color: colors.grey[300] }}
              justifyContent='center'>
              {dayjs(updatedAt).format('hh:mm A')}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      width: 80,
      renderCell: ({ row: { createdAt } }, i) => {
        return (
          <Stack>
            <Typography
              display='flex'
              sx={{ fontSize: 12 }}
              justifyContent='center'>
              {dayjs(createdAt).format('MM-DD-YY')}
            </Typography>
            <Typography
              display='flex'
              sx={{ fontSize: 12, color: colors.grey[300] }}
              justifyContent='center'>
              {dayjs(createdAt).format('hh:mm A')}
            </Typography>
          </Stack>
        );
      },
    },
  ];

  const hoverStyles = [
    {
      '&:hover': {
        backgroundColor: colors.greenAccent[600],
      },
    },
  ];

  //Modal Stuff
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 420,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    borderRadius: 1,
    boxShadow: 24,
    // p: 4,
    overflowY: 'auto',
  };

  // Payroll Create Stuff
  const createPayroll = async (details) => {
    const response = await axios.post(
      '/api/v1/timecards',
      JSON.stringify(details),
      {
        baseURL: baseURL,
      }
    );

    const updatedPayroll = [...allTimecards, response.data.data];
    setAllTimecards(updatedPayroll);
  };

  //updating state
  const deleteById = (id) => {
    const copyTimecards = [...allTimecards];
    //filter keeps all id thats not selected
    const filteredData = copyTimecards.filter((item) => item.id !== id);
    // Do something with the filtered data

    setAllTimecards(filteredData);
  };

  //update state
  const handleRemoveMultipleItems = () => {
    selectedDataID.forEach((id) => deleteById(id));
  };

  //create call back to edit payrollz using an array of id
  async function deleteManyTimecards(...prevPayroll) {
    var cannotDelete = false;
    //Check if any of the selected are already approved
    selectedDataID.map((id) => {
      const search = allTimecards.find((timecard) => timecard._id === id);
      if (search && search.approved === true) {
        setErrMsg('Cannot Delete Approved Timecards');
        cannotDelete = true;
      }
      return search;
    });
    if (!cannotDelete) {
      await Promise.all(
        selectedDataID.map((id) =>
          axios.delete(`/api/v1/timecardsv2/${id}`, {
            'Access-Control-Allow-Credentials': true,
            baseURL: baseURL,
          })
        )
      )
        .catch(function (err) {
          console.log(err);
        })
        .finally(handleRemoveMultipleItems());
    }
  }

  const approveTimeCardById = async (id) => {
    try {
      const body = {
        payload: {
          approved: true,
          'approvedBy.user': user._id,
          action: 'approve',
        },
        userID: user._id,
      };

      await updatedPutRequest(id, body); // Use the imported function

      updateObjFieldInArray(id, user, body, setAllTimecards);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  async function updatedPutRequest(id, body) {
    await api
      .put(`/api/v1/timecardsv2/approve/${id}`, body)
      .catch((err) => {
        console.log('Error', err);
        setErrState(true);
        seterrMsg('Error Loading Data');
      })
      .then((response) => {
        setErrState(false);
        seterrMsg('');
        console.log(response);
      });
  }

  const handleMarkPaid = async (id) => {
    try {
      const body = {
        payload: { paid: true, 'paidBy.user': user._id, action: 'paid' },
        userID: user._id,
      };
      await updatedPutRequest(id, body); // Use the imported function

      updateObjFieldInArray(id, user, body, setAllTimecards);
    } catch (error) {}
  };

  const updateObjFieldInArray = (itemId, user, body, setStateFunc) => {
    const updatedItems = [...allTimecards];

    const itemIndex = updatedItems.findIndex((item) => item.id === itemId);

    if (itemIndex !== -1 && body.payload.action === 'paid') {
      updatedItems[itemIndex].paidBy = user;
      updatedItems[itemIndex].paid = true;
    } else if (itemIndex !== -1 && body.payload.action === 'approve') {
      updatedItems[itemIndex].approvedBy = user;
      updatedItems[itemIndex].approved = true;
    }

    setStateFunc(updatedItems);
  };

  const handleApproveMultipleItems = () => {
    selectedDataID.forEach((id) => approveTimeCardById(id));
  };

  const handleMarkPaidMultipleItems = () => {
    selectedDataID.forEach((id) => handleMarkPaid(id));
  };

  let navigate = useNavigate();
  const routeChange = () => {
    let path = `/PayrollCreatePage`;
    navigate(path);
  };

  const handlePopOutClickViewPayroll = (event, details) => {
    setanchorElViewPayrollPopOut(event.currentTarget);
    setShowPayrollView(true);
    setpopOverDetails(details.row.row);
  };

  const handlePopOutClickEditPayroll = (event, details) => {
    setanchorElEditPayrollPopOut(event.currentTarget);
    setShowPayrollEdit(true);
    setpopOverEditDetails(details.row.row);
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton sx={hoverStyles} />
        <GridToolbarFilterButton sx={hoverStyles} />
        <GridToolbarDensitySelector sx={hoverStyles} />
        <GridToolbarExport sx={hoverStyles} />
        <Button
          sx={hoverStyles}
          startIcon={<PostAddIcon />}
          onClick={routeChange}>
          Add Timecard
        </Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'>
          <Box sx={style}>
            <div className='center'>
              <PayrollCreate2
                onPayrollCreate={createPayroll}
                user={user}
                payrollz={allTimecards}
              />
            </div>
          </Box>
        </Modal>
        {isAdmin ? (
          <Button
            sx={hoverStyles}
            startIcon={<DoneOutlineIcon />}
            onClick={handleApproveMultipleItems}>
            Approve
          </Button>
        ) : null}

        <Button
          sx={hoverStyles}
          startIcon={<DeleteForeverIcon />}
          onClick={deleteManyTimecards}>
          Delete
        </Button>
        <Button
          sx={hoverStyles}
          startIcon={<AttachMoneyIcon />}
          onClick={handleMarkPaidMultipleItems}>
          Mark Paid
        </Button>

        <QryPopOut
          project={project}
          setProject={setProject}
          startQry={startQry}
          setStartQry={setStartQry}
          endQry={endQry}
          setEndQry={setEndQry}
          editMode={editMode}
          setEditMode={setEditMode}
        />
      </GridToolbarContainer>
    );
  }

  function CustomFooter() {
    let count, reg, OT, sat, sun, total;

    if (pageState?.data?.length > 0) {
      count = pageState.data.length;
      reg = pageState.data.reduce((n, { regHours }) => n + regHours, 0);
      OT = pageState.data.reduce(
        (n, { overtimeHours }) => n + overtimeHours,
        0
      );
      sat = pageState.data.reduce((n, { satHours }) => n + satHours, 0);
      sun = pageState.data.reduce((n, { sunHours }) => n + sunHours, 0);
      total = reg + OT + sat + sun;
    }
    return (
      <GridFooterContainer>
        {/* Add what you want here */}
        <Typography variant='body2' sx={{ ml: '10px' }}>
          {' '}
          Count: {count}
        </Typography>
        <Typography variant='body2' sx={{ ml: '10px' }}>
          {' '}
          Reg: {reg}
        </Typography>
        <Typography variant='body2' sx={{ ml: '10px' }}>
          {' '}
          OT: {OT}
        </Typography>
        <Typography variant='body2' sx={{ ml: '10px' }}>
          {' '}
          Sat: {sat}
        </Typography>
        <Typography variant='body2' sx={{ ml: '10px' }}>
          {' '}
          Sun: {sun}
        </Typography>
        <Typography variant='body2' sx={{ ml: '10px' }}>
          {' '}
          Total: {total}
        </Typography>
        <GridFooter
          sx={{
            border: 'none', // To delete double border.
          }}
        />
      </GridFooterContainer>
    );
  }

  return (
    <Box m='10px 20px 20px 20px'>
      <Header title='Timecard' subtitle='Managing the Timecard Members' />

      <Box
        m='0'
        height='70vh'
        sx={{
          '& .MuiDataGrid-root': {
            border: 'none',
          },
          '& .MuiDataGrid-cell': {
            borderBottom: 'none',
          },
          '& .name-column--cell': {
            color: colors.greenAccent[300],
          },
          '& .name=column--cell': {
            color: colors.greenAccent[300],
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: colors.blueAccent[700],
            borderBottom: 'none',
          },
          '& .MuiDataGrid-virtualScroller': {
            backgroundColor: colors.primary[400],
          },
          '& .MuiDataGrid-footerContainer': {
            borderTop: 'none',
            backgroundColor: colors.blueAccent[700],
          },
          '& .MuiDataGrid-toolbarContainer .MuiButton-text ': {
            color: `${colors.grey[100]} !important`,
          },
        }}>
        <DataGrid
          rows={allTimecards}
          rowCount={pageState?.total || 100}
          loading={pageState.isLoading}
          pagination
          page={pageState.page}
          pageSize={pageState.pageSize}
          pageSizeOptions={[50, 100]}
          onPageChange={(newPage) =>
            setPageState((old) => ({ ...old, page: newPage }))
          }
          onPageSizeChange={(newPageSize) =>
            setPageState((old) => ({ ...old, pageSize: newPageSize }))
          }
          paginationMode='server'
          columns={columns}
          components={{ Toolbar: CustomToolbar, Footer: CustomFooter }}
          checkboxSelection={true}
          onSelectionModelChange={(ids) => {
            setselectedDataID(ids);
          }}
          rowHeight={40}
          selectedDataID={selectedDataID}
          sx={{
            '& .MuiCheckbox-colorPrimary': {
              color: colors.blueAccent[500],
            },
            row: {
              '&.Mui-selected': {
                backgroundColor: colors.blueAccent[500],
                color: colors.grey[500],
                '&:hover': {
                  backgroundColor: 'purple',
                },
              },
            },
            '& .MuiDataGrid-row::aria-selected': {
              backgroundColor: colors.blueAccent[300],
              borderBottom: 'none',
            },
          }}
          disableSelectionOnClick
        />

        {showPayrollView ? (
          <div>
            <ViewPayrollPopOver
              message={popOverDetails}
              anchorElPopOut={anchorElViewPayrollPopOut}
              openPopOut={openViewPayrollPopOut}
              idPopOut={idViewPayrollPopOut}
              setAnchorElPopOut={setanchorElViewPayrollPopOut}
            />
          </div>
        ) : null}
        {showPayrollEdit ? (
          <div>
            <EditPayrollPopOver
              details={popOverEditDetails}
              anchorElPopOut={anchorElEditPayrollPopOut}
              openPopOut={openEditPayrollPopOut}
              idPopOut={idEditPayrollPopOut}
              setAnchorElPopOut={setanchorElEditPayrollPopOut}
              user={user}
            />
          </div>
        ) : null}
      </Box>
    </Box>
  );
};

export default Timecard;
