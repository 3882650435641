export const productionPlans = [
	{
		id: "price_1PhyHIHiJ3dXu5VeA4DD53bX",
		plan_name: "Starter - 1 Month",
		price: 20,
		frequency_months: 1,
		mode: "production",
		seats: 10,
		features: ["Up to 10 users", "Billed Every 30 Days", "$0.66 per day"],
		active: false,
	},
	{
		id: "price_1PhyTgHiJ3dXu5VeHCcjtoTg",
		plan_name: "Starter - 6 Month",
		price: 110,
		frequency_months: 6,
		mode: "production",
		seats: 10,
		features: ["Up to 40 users", "Billed Every 6 Months", "$1.33 per day"],
		active: false,
	},
	{
		id: "price_1Phyk8HiJ3dXu5VebCu6m49M",
		plan_name: "Starter - 12 Month",
		price: 200,
		frequency_months: 12,
		mode: "production",
		seats: 10,
		features: ["Up to 80 users", "Billed Every 12 Months", "$2.60 per day"],
		active: false,
	},
	{
		id: "price_1PhyvWHiJ3dXu5Ve9vLkFTCx",
		plan_name: "Premium - 1 Month",
		price: 40,
		frequency_months: 1,
		mode: "production",
		seats: 25,
		features: ["Up to 40 users", "Billed Every 1 Month", "$XX.XX per day"],
		active: false,
	},
	{
		id: "price_1PlDjBHiJ3dXu5Vec9LeRWJ8",
		plan_name: "Premium - 6 Month",
		price: 220,
		frequency_months: 6,
		mode: "production",
		seats: 25,
		features: ["Up to 40 users", "Billed Every 6 Months", "$XX.XX per day"],
		active: false,
	},
	{
		id: "price_1PlDkRHiJ3dXu5Ve58QF5Vp3",
		plan_name: "Premium - 12 Month",
		price: 400,
		frequency_months: 12,
		mode: "production",
		seats: 25,
		features: ["Up to 40 users", "Billed Every 12 Months", "$XX.XX per day"],
		active: false,
	},
	{
		id: "price_1PlDrrHiJ3dXu5VeG0eBXOni",
		plan_name: "Pro - 1 Month",
		price: 60,
		frequency_months: 1,
		mode: "production",
		seats: 80,
		features: ["Up to 80 users", "Billed Every 1 Month", "$XX.XX per day"],
		active: false,
	},
	{
		id: "price_1PlDsNHiJ3dXu5Vega0s8mZI",
		plan_name: "Pro - 6 Month",
		price: 330,
		frequency_months: 6,
		mode: "production",
		seats: 80,
		features: ["Up to 80 users", "Billed Every 6 Months", "$XX.XX per day"],
		active: false,
	},
	{
		id: "price_1PlDsxHiJ3dXu5VeMlirjNRX ",
		plan_name: "Pro - 12 Month",
		price: 600,
		frequency_months: 12,
		mode: "production",
		seats: 80,
		features: ["Up to 80 users", "Billed Every 12 Months", "$XX.XX per day"],
		active: false,
	},
];

export const developmentPlans = [
	{
		id: "price_1OorqeHiJ3dXu5VeluFZjYW8",
		plan_name: "Starter - 1 Month",
		price: 20,
		frequency_months: 1,
		mode: "development",
		seats: 10,
		features: ["Up to 10 users", "Billed Every 1 Month"],
		active: false,
		per_day: 0.66,
	},
	{
		id: "price_1OpI1kHiJ3dXu5Veqyko2QtM",
		plan_name: "Starter - 6 Month",
		price: 110,
		frequency_months: 6,
		mode: "development",
		seats: 10,
		features: ["Up to 10 users", "Billed Every 6 Months"],
		active: false,
		per_day: 0.6,
	},
	{
		id: "price_1OpI1zHiJ3dXu5VemsouXqH0",
		plan_name: "Starter - 12 Month",
		price: 200,
		frequency_months: 12,
		mode: "development",
		seats: 10,
		features: ["Up to 10 users", "Billed Every 12 Months"],
		active: false,
		per_day: 0.54,
	},
	{
		id: "price_1Oorv2HiJ3dXu5Vetfq5y9h0",
		plan_name: "Premium - 1 Month",
		price: 40,
		frequency_months: 1,
		mode: "development",
		seats: 25,
		features: ["Up to 25 users", "Billed Every 1 Month"],
		active: false,
		per_day: 1.3,
	},
	{
		id: "price_1OpIHGHiJ3dXu5VeaD74NuvS",
		plan_name: "Premium - 6 Month",
		price: 220,
		frequency_months: 6,
		mode: "development",
		seats: 25,
		features: ["Up to 25 users", "Billed Every 6 Months"],
		active: false,
		per_day: 1.2,
	},
	{
		id: "price_1OpIHQHiJ3dXu5VeBF081Uz9",
		plan_name: "Premium - 12 Month",
		price: 400,
		frequency_months: 12,
		mode: "development",
		seats: 25,
		features: ["Up to 25 users", "Billed Every 12 Months"],
		active: false,
		per_day: 1.09,
	},
	{
		id: "price_1OorviHiJ3dXu5VeezPKJGqB",
		plan_name: "Pro - 1 Month",
		price: 60,
		frequency_months: 1,
		mode: "development",
		seats: 80,
		features: ["Up to 80 users", "Billed Every 1 Month"],
		active: false,
		per_day: 2,
	},
	{
		id: "price_1OpIHhHiJ3dXu5VeU4F9YA6F",
		plan_name: "Pro - 6 Month",
		price: 330,
		frequency_months: 6,
		mode: "development",
		seats: 80,
		features: ["Up to 80 users", "Billed Every 6 Months"],
		active: false,
		per_day: 1.8,
	},
	{
		id: "price_1OpIHqHiJ3dXu5VeW8UsDbcN",
		plan_name: "Pro - 12 Month",
		price: 600,
		frequency_months: 12,
		mode: "development",
		seats: 80,
		features: ["Up to 80 users", "Billed Every 12 Months"],
		active: false,
		per_day: 1.64,
	},
];
