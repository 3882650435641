import React from "react";
import { Box } from "@mui/material";
import { Typography } from "@mui/material";
import CustomSelect from "../styledcomponents/CustomSelect";
import dayjs from "dayjs";

const TopBarDashboardSettings = ({
	monthlySearchFilters,
	setMonthlySearchFilters,
	professionalSearchFilters,
	setProfessionalSearchFilters,
	listData,
}) => {
	const monthRange = Array.from({ length: 12 }, (_, i) => i + 1);
	const yearRange = Array.from(
		{
			length: monthlySearchFilters.endYear - monthlySearchFilters.startYear + 1,
		},
		(_, i) => i + monthlySearchFilters.startYear
	);
	const pastTwelveMonthsLabels = Array.from({ length: 12 }, (_, i) =>
		dayjs().subtract(i, "month").format("MM/YY")
	).reverse();
	const handleProfessionChange = (field) => (value) => {
		setProfessionalSearchFilters((prevState) => ({
			...prevState,
			[field]: value, // Update the specific field dynamically
		}));
	};
	console.log(listData);

	const handleMonthlyStartSearchFilterChange = (value) => {
		// Parse the date with a default day (1st of the month)
		const parsedDate = dayjs(value, "MM/YY");

		// Extract month and year
		const month = parsedDate.month() + 1; // Month is zero-based in dayjs
		const year = parsedDate.year();
		setMonthlySearchFilters((prevState) => ({
			...prevState,
			startMonth: month, // Update the specific field dynamically
			startYear: year,
		}));
	};

	const handleMonthlyEndingSearchFilterChange = (value) => {
		// Parse the date with a default day (1st of the month)
		const parsedDate = dayjs(value, "MM/YY");

		// Extract month and year
		const month = parsedDate.month() + 1; // Month is zero-based in dayjs
		const year = parsedDate.year();
		setMonthlySearchFilters((prevState) => ({
			...prevState,
			endMonth: month, // Update the specific field dynamically
			endYear: year,
		}));
	};

	//NOTES project passed down to 7, time range passed to 4
	//b through i
	//b, d, e, f
	return (
		<Box display="flex" justifyContent="center" spacing={2}>
			<CustomSelect
				value={professionalSearchFilters.project}
				optionsArray={[
					"All Projects",
					...(listData?.projectList?.data?.data || []),
				]}
				handleChange={(value) => handleProfessionChange("project")(value)}
			/>
			<CustomSelect
				value={professionalSearchFilters.role}
				optionsArray={[
					"All Trades",
					...(listData?.craftlist?.data?.data || []),
				]}
				handleChange={(value) => handleProfessionChange("role")(value)}
			/>
			<CustomSelect
				value={professionalSearchFilters.classification}
				optionsArray={[
					"All Classes",
					...(listData?.classificationlist?.data.data || []),
				]}
				handleChange={(value) =>
					handleProfessionChange("classification")(value)
				}
			/>
			<CustomSelect
				value={pastTwelveMonthsLabels[0]}
				optionsArray={pastTwelveMonthsLabels}
				handleChange={(newValue) => {
					handleMonthlyStartSearchFilterChange(newValue);
				}}
				width={"75px"}
			/>
			<CustomSelect
				value={pastTwelveMonthsLabels[11]}
				optionsArray={pastTwelveMonthsLabels}
				handleChange={(newValue) => {
					handleMonthlyEndingSearchFilterChange(newValue);
				}}
				width={"75px"}
			/>
		</Box>
	);
};

export default TopBarDashboardSettings;
