//mui typography two tone inline text
import React from 'react';
import Typography from '@mui/joy/Typography';
import Box from '@mui/joy/Box';
import Stack from '@mui/joy/Stack';

const TwoToneInlineMui = ({ text1, text2, color1, color2 }) => {
  return (
    <Box>
      <Stack direction='row' spacing={0.5}>
        <Typography level='body-xs' component='span' textColor={color1}>
          {text1}
        </Typography>
        <Typography level='body-xs' component='span' textColor={'grey'}>
          {': '}
        </Typography>
        <Typography level='body-xs' component='span' textColor={color2}>
          ${text2}
        </Typography>
      </Stack>
    </Box>
  );
};

export default TwoToneInlineMui;
