import { useMemo, useState, useEffect } from "react";
import DashBoardBox from "../components/DashBoardBox";
import AnalyticsBoxHeader from "../components/AnalyticsBoxHeader";
import { useTheme, Box, Stack } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../theme";
import Alert from "@mui/material/Alert";
import {
	ResponsiveContainer,
	CartesianGrid,
	XAxis,
	YAxis,
	Tooltip,
	Line,
	LineChart,
	Legend,
	Bar,
	BarChart,
} from "recharts";
import { useAuth } from "../context/AuthProvider";
import AnalyticsBoxHeader3 from "../components/AnalyticsBoxHeader3";
import CustomSelect from "../styledcomponents/CustomSelect";
import dayjs from "dayjs";

const Row2 = ({
	swData,
	error,
	errMsg,
	professionalSearchFilters,
	monthlySearchFilters,
}) => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const [options, setOptions] = useState([]);
	const [swDataInverted, setSwDataInverted] = useState([]);
	const [headerLabel, setHeaderLabel] = useState("");
	const isoWeek = require("dayjs/plugin/isoWeek");
	dayjs.extend(isoWeek);
	const renderedColumns = useMemo(() => {
		const headers = [
			"key",
			"month",
			"year",
			"reg",
			"ot",
			"sat",
			"sun",
			"totalHours",
		];
		//create columns for each trade
		const columns = headers.map((elem) => {
			return {
				field: elem,
				headerName: elem,
				flex: 0.4,
			};
		});
		return columns;
	}, []);

	const COLORS = [
		colors.greenAccent[500],
		colors.redAccent[500],
		colors.blueAccent[500],
		colors.grey[500],
	];

	const monthNames = [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec",
	];

	useEffect(() => {
		if (swData) {
			const updatedData = swData.map((item) => ({
				...item,
				monthLetter: monthNames[item.month - 1][0], // Get first letter of month
			}));

			setSwDataInverted(updatedData.slice().reverse());
		}
	}, [swData]);

	// Function to get the start date of the given week and year
	function getWeekStartDate(year, month) {
		// Handle null or undefined values by setting defaults
		const validYear = year ?? dayjs().year(); // Use current year if year is null or undefined
		const validMonth = month ? month - 1 : 1; // Default to week 1 if Month is null or undefined

		// Ensure the inputs are valid before proceeding
		if (!validYear || !validMonth) {
			return dayjs(); // Return today's date if inputs are invalid
		}

		// Calculate the start of the week based on the provided year and weekNumber
		return dayjs().year(validYear).month(validMonth).format("MM/YYYY");
	}

	useEffect(() => {
		if (monthlySearchFilters) {
			const formattedStart = getWeekStartDate(
				monthlySearchFilters?.startYear,
				monthlySearchFilters?.startMonth
			);
			const formattedEnd = getWeekStartDate(
				monthlySearchFilters?.endYear,
				monthlySearchFilters?.endMonth
			);
			setHeaderLabel(formattedStart + "-" + formattedEnd);
		}
	}, [monthlySearchFilters]);

	return (
		<>
			<DashBoardBox gridArea="d" theme={theme} colors={colors}>
				<Stack direction="row" spacing={2} justifyContent="space-between">
					<AnalyticsBoxHeader3
						title="Total Hours Data Grid"
						subtitle={professionalSearchFilters?.project + "-" + headerLabel}
					/>
				</Stack>
				{error?.sw ? <Alert severity="error">{errMsg.sw}</Alert> : null}

				{
					//if there is no data, show nothing, else show the
					//DataGrid with the data
					swData && swData.length === 0 ? (
						<Box height="0%" />
					) : (
						<ResponsiveContainer width="95%" height="80%">
							<Box
								p="0 0.5rem"
								height="85%"
								sx={{
									"& .MuiDataGrid-root": {
										color: `${colors.grey[300]}`,
										border: "none",
									},
									"& .MuiDataGrid-cell": {
										borderBottom: `1px solid ${colors.grey[800]} !important`,
									},
									"& .MuiDataGrid-columnSeparator": {
										visibility: "hidden",
									},
								}}
							>
								<DataGrid
									rowHeight={20}
									columnHeaderHeight={25}
									hideFooter={true}
									rows={swData || []}
									columns={renderedColumns || []}
									pageSizeOptions={[-1]}
								/>
							</Box>
						</ResponsiveContainer>
					)
				}
			</DashBoardBox>
			<DashBoardBox gridArea="e" theme={theme} colors={colors}>
				<Stack direction="row" spacing={2} justifyContent="space-between">
					<AnalyticsBoxHeader3
						title="Total Hours Line Graph"
						subtitle={professionalSearchFilters?.project + "-" + headerLabel}
					/>
				</Stack>
				{error?.sw ? <Alert severity="error">{errMsg.sw}</Alert> : null}
				{swData && swData.length === 0 ? (
					<Box height="0%" />
				) : (
					<ResponsiveContainer width="100%" height="80%">
						<LineChart
							width={300}
							height={200}
							data={swDataInverted}
							margin={{
								top: 15,
								right: 0,
								left: 0,
								bottom: 20,
							}}
						>
							<CartesianGrid vertical={false} stroke={colors.grey[700]} />
							<XAxis
								dataKey="monthLetter"
								tick={({ x, y, payload, index }) => {
									// Find the corresponding data entry
									// Get the corresponding data item based on the index
									const dataItem = swDataInverted?.[index];

									return (
										<text
											x={x}
											y={y}
											dy={16}
											textAnchor="middle"
											fill={
												dataItem?.year === dayjs().year() ? "white" : "grey"
											}
											style={{ fontSize: 12 }}
										>
											{payload.value}
										</text>
									);
								}}
								stroke={colors.grey[100]}
							/>
							<YAxis
								yAxisId="left"
								tickLine={false}
								axisLine={false}
								stroke={colors.grey[100]}
							/>
							<YAxis
								yAxisId="right"
								orientation="right"
								tickLine={false}
								axisLine={false}
								stroke={colors.grey[100]}
							/>
							<Tooltip />
							<Legend
								height={0}
								wrapperStyle={{
									margin: "0 0 0 0",
								}}
							/>
							<Line
								yAxisId="left"
								type="monotone"
								dataKey="totalHours"
								stroke={colors.greenAccent[500]}
							/>
							<Line
								yAxisId="right"
								type="monotone"
								dataKey="ot"
								stroke={colors.redAccent[500]}
							/>
						</LineChart>
					</ResponsiveContainer>
				)}
			</DashBoardBox>
			<DashBoardBox gridArea="f" theme={theme} colors={colors}>
				<Stack direction="row" spacing={2} justifyContent="space-between">
					<AnalyticsBoxHeader3
						title="Total Hours Stacked Bar"
						subtitle={professionalSearchFilters?.project + "-" + headerLabel}
					/>
				</Stack>
				{error?.sw ? <Alert severity="error">{errMsg.sw}</Alert> : null}

				{swDataInverted && swDataInverted.length === 0 ? (
					<Box height="0%" />
				) : (
					<ResponsiveContainer width="100%" height="80%">
						<BarChart
							width={700}
							height={300}
							data={swDataInverted}
							margin={{ top: 20, right: 40, left: 10, bottom: 5 }}
							barSize={20}
						>
							{/* Solid Grid Lines */}
							<CartesianGrid strokeDasharray="0" stroke="grey" />

							{/* X-Axis: Conditionally Styled */}
							<XAxis
								dataKey="monthLetter"
								tick={({ x, y, payload, index }) => {
									// Find the corresponding data entry
									// Get the corresponding data item based on the index
									const dataItem = swDataInverted?.[index];

									return (
										<text
											x={x}
											y={y}
											dy={16}
											textAnchor="middle"
											fill={
												dataItem?.year === dayjs().year() ? "white" : "grey"
											}
											style={{ fontSize: 12 }}
										>
											{payload.value}
										</text>
									);
								}}
								// Ensures tick labels are visible
								tickCount={12}
								interval={0}
							/>

							{/* Y-Axis: Ensure stroke & tick visibility */}
							<YAxis stroke="white" tick={{ fill: "white" }} />

							{/* Tooltip & Legend */}
							<Tooltip />
							<Legend />

							{/* Dynamically Generated Bars */}
							{options?.map((entry, index) => (
								<Bar
									key={`bar-${index}`}
									dataKey={entry}
									stackId="a"
									fill={COLORS[index % COLORS.length]}
								/>
							))}

							{/* Hardcoded Bars */}
							<Bar dataKey="reg" stackId="a" fill="#8884d8" />
							<Bar dataKey="ot" stackId="a" fill="#82ca9d" />
							<Bar dataKey="sat" stackId="a" fill="#32a852" />
							<Bar dataKey="sun" stackId="a" fill="#3a32a8" />
						</BarChart>
					</ResponsiveContainer>
				)}
			</DashBoardBox>
		</>
	);
};

export default Row2;
