import { Box, Stack, Tab, Tabs } from "@mui/material";
import TopBarAdmin from "../components/TopBarAdmin";
import Header from "../components/Header";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../context/AuthProvider";
import CompanySubscription from "../components/Settings/CompanySubscription";
import CompanySettings from "../components/Settings/CompanySettings";
import UserProfile from "../components/Settings/UserProfile";
import SettingsInfo from "../components/Settings/SettingsInfo";
import CheckOutButton from "../components/CheckOutButton";
import UserChangePassword from "../components/Settings/UserChangePassword";
import Alert from "@mui/material/Alert";
import RunningWithErrorsIcon from "@mui/icons-material/RunningWithErrors";
import { Grid, Card, CardContent, Typography, Button } from "@mui/material";
import { productionPlans, developmentPlans } from "../assets/plans";
import CreditCardOffIcon from "@mui/icons-material/CreditCardOff";
import CreditScoreIcon from "@mui/icons-material/CreditScore";

const SettingsTabPanel = (props) => {
	const { children, value, index, ...other } = props;
	console.log(productionPlans);
	return (
		<Box
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
			sx={{ maxWidth: "sm", marginX: "auto" }}
		>
			{value === index && <Box sx={{ p: 3 }}>{children}</Box>}
		</Box>
	);
};

const SettingsPage = () => {
	const [selectedTab, setSelectedTab] = useState(0);
	const [notificationsNumber, setnotificationsNumber] = useState(0);
	const handleChange = (event, newValue) => setSelectedTab(newValue);
	const { api, user, subscription } = useAuth();
	const [errState, seterrState] = useState(false);
	const [company, setCompany] = useState({});
	const [plans, setPlans] = useState([]);
	const [selectedPlan, setSelectedPlan] = useState([]);
	console.log(process.env.REACT_APP_NODE_ENV);
	console.log(subscription);

	useEffect(() => {
		if (
			process.env.REACT_APP_NODE_ENV === "production" &&
			productionPlans &&
			subscription?.plan
		) {
			setPlans(productionPlans);
			setSelectedPlan(
				productionPlans.find((plan) => plan.id === subscription.plan.id) || null
			);
		} else if (developmentPlans && subscription?.plan) {
			setPlans(developmentPlans);
			setSelectedPlan(
				developmentPlans.find((plan) => plan.id === subscription.plan.id) ||
					null
			);
		}
	}, [
		developmentPlans,
		productionPlans,
		subscription,
		process.env.REACT_APP_NODE_ENV,
	]);

	useEffect(() => {
		async function GetResults(data) {
			try {
				const response = await api.get(
					`/api/v1/timecardsv2/unapproved/?page=1&limit=1000`
				);
				setnotificationsNumber(response?.data.data[0].results.length);
				seterrState(false);
			} catch (err) {
				console.log("Failed", { err });
				seterrState(true);
			}
		}
		GetResults();
	}, [api]);

	const handleConversionCheckout = async (priceId) => {
		//const stripe = await stripePromise;

		try {
			const response = await api.post(
				"/api/v1/subscription/create-checkout-session",
				{ priceId }
			);
			const url = response.data.url;
			console.log(url);
			console.log(subscription);
			window.location.href = url;
			const sessionId = response.data.sessionId;

			//setSessionId(response.data.sessionId);

			// const { error } = await stripe.redirectToCheckout({
			// 	sessionId: sessionId,
			// });

			// if (error) {
			// 	console.error(error);
			// }
		} catch (err) {
			console.log(err);
		}
	};

	const handlePlanSwitch = (planId) => {
		console.log(planId);
	};

	useEffect(() => {
		if (user.company?.id) {
			api
				.get(`/api/v1/company/${user.company.id}`)
				.then(({ data }) => {
					setCompany(data.data);
				})
				.catch((err) => {
					setCompany({});
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [api, user.company?.id]);

	const HeaderDetails = () => {
		return (
			<Stack direction="row">
				<Typography> Update Your Settings</Typography>
				{subscription?.cardonfile === true ? (
					<CreditScoreIcon />
				) : (
					<CreditCardOffIcon />
				)}
			</Stack>
		);
	};

	const Plans = () => {
		return (
			<Box sx={{ p: 3 }}>
				<Grid
					container
					spacing={4}
					justifyContent="center"
					columns={{ xs: 4, sm: 8, md: 12 }}
				>
					{plans.map((plan, index) => (
						<Grid item key={index} xs={12} sm={6} md={4}>
							<Card
								sx={{
									borderRadius: "12px",
									boxShadow: 3,
									transition: "transform 0.3s, box-shadow 0.3s",
									"&:hover": {
										transform: "scale(1.05)",
										boxShadow: 6,
									},
								}}
							>
								<CardContent sx={{ textAlign: "center" }}>
									<Typography
										variant="h7"
										component="div"
										sx={{ fontWeight: "bold", mb: 2 }}
									>
										{plan?.plan_name}
									</Typography>
									<Typography
										variant="h6"
										component="div"
										sx={{ color: "success.main", mb: 3 }}
									>
										${plan?.price} for {plan?.frequency_months} months
									</Typography>
									<Box component="ul" sx={{ listStyle: "none", p: 0, mb: 3 }}>
										{plan?.features?.map((feature, i) => (
											<Typography
												component="li"
												key={i}
												sx={{ mb: 1, fontSize: "1rem" }}
											>
												{feature}
											</Typography>
										))}
									</Box>
									<Typography
										variant="h6"
										component="div"
										sx={{ color: "success.main", mb: 3 }}
									>
										${plan?.per_day} per day
									</Typography>
									<Button
										variant="contained"
										color="success"
										disabled={plan?.id === selectedPlan?._id ? true : false}
										onClick={() => {
											subscription?.status === "active"
												? handlePlanSwitch(plan.id)
												: handleConversionCheckout(plan.id);
										}}
									>
										{plan?.id === selectedPlan?.id ? "Selected" : "Choose Plan"}
									</Button>
								</CardContent>
							</Card>
						</Grid>
					))}
				</Grid>
			</Box>
		);
	};

	return (
		<Box sx={{ height: "140dvh" }} m="10px 20px 20px 20px">
			{errState ? (
				<Alert
					icon={<RunningWithErrorsIcon fontSize="inherit" />}
					severity="error"
				>
					{"Data Not Loaded!  Please refresh"}
				</Alert>
			) : null}
			<TopBarAdmin notificationsNumber={notificationsNumber} />
			<Header title="Settings" subtitle={HeaderDetails()} />

			<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
				<Tabs value={selectedTab} onChange={handleChange}>
					<Tab label="Company Settings" />
					<Tab label="User Profile" />
					<Tab label="Subscription Info" />
					<Tab label="Payment Info" />
					<Tab
						label={
							subscription?.status === "active" ? "Switch Plans" : "Select Plan"
						}
					/>
				</Tabs>
			</Box>
			<SettingsTabPanel value={selectedTab} index={0}>
				<Stack spacing={4}>
					<CompanySettings />
					{/* <CompanySubscription company={company} setCompany={setCompany} /> */}
				</Stack>
			</SettingsTabPanel>
			<SettingsTabPanel value={selectedTab} index={1}>
				<Stack spacing={4}>
					<UserProfile />
					<UserChangePassword />
				</Stack>
			</SettingsTabPanel>

			<SettingsTabPanel value={selectedTab} index={2}>
				<Stack spacing={4}>
					<SettingsInfo data={company} />
				</Stack>
			</SettingsTabPanel>

			<SettingsTabPanel value={selectedTab} index={3}>
				<Stack spacing={4}>
					<Typography>Payment Info</Typography>
					<Link to="/company/subscription">Pay Now</Link>
					<CheckOutButton
						priceId={"price_1OpI1kHiJ3dXu5Veqyko2QtM"}
						subscriptionId={subscription?.customer}
					/>
				</Stack>
			</SettingsTabPanel>

			<SettingsTabPanel value={selectedTab} index={4}>
				<Stack spacing={4}>
					<Typography>Change Plans</Typography>
					<Typography>
						Status = Active 1. Show selected Plan 2. Change plan options
						(already has payment method)3. Cancel Plan{" "}
					</Typography>
					<Typography>
						Status = Trial 1. Show selected Plan with Trial 2. Change plan
						options 3. Select Payment Method
					</Typography>
					<Typography>No Payment Method</Typography>
					<Plans />
				</Stack>
			</SettingsTabPanel>
		</Box>
	);
};

export default SettingsPage;
