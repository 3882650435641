import { useMemo, useState, useEffect } from "react";
import DashBoardBox from "../components/DashBoardBox";
import AnalyticsBoxHeader from "../components/AnalyticsBoxHeader";
import { useTheme, Box, Stack, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../theme";
import Alert from "@mui/material/Alert";
import useFetch from "../hooks/fetchAPI";
import dayjs from "dayjs";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CustomSelect from "../styledcomponents/CustomSelect";
import AnalyticsBoxHeader3 from "../components/AnalyticsBoxHeader3";
import {
	ResponsiveContainer,
	CartesianGrid,
	XAxis,
	YAxis,
	Tooltip,
	Line,
	LineChart,
	Legend,
	Bar,
	BarChart,
} from "recharts";

const fakedata = [
	{
		name: "May",
		rfis: 4000,
		change_orders: 2400,
		non_compliance_reports: 2400,
	},
	{
		name: "Jun",
		rfis: 3000,
		change_orders: 1398,
		non_compliance_reports: 2210,
	},
	{
		name: "Jul",
		rfis: 2000,
		change_orders: 9800,
		non_compliance_reports: 2290,
	},
	{
		name: "Aug",
		rfis: 2780,
		change_orders: 3908,
		non_compliance_reports: 2000,
	},
	{
		name: "Sep",
		rfis: 1890,
		change_orders: 4800,
		non_compliance_reports: 2181,
	},
	{
		name: "Oct",
		rfis: 1590,
		change_orders: 9000,
		non_compliance_reports: 3500,
	},
];

const fakedata2 = [
	{
		id: 1,
		name: "May",
		rfis: 4000,
		change_orders: 2400,
		non_compliance_reports: 2400,
	},
	{
		id: 2,
		name: "Jun",
		rfis: 3000,
		change_orders: 1398,
		non_compliance_reports: 2210,
	},
	{
		id: 3,
		name: "Jul",
		rfis: 2000,
		change_orders: 9800,
		non_compliance_reports: 2290,
	},
	{
		id: 4,
		name: "Aug",
		rfis: 2780,
		change_orders: 3908,
		non_compliance_reports: 2000,
	},
	{
		id: 5,
		name: "Sep",
		rfis: 1890,
		change_orders: 4800,
		non_compliance_reports: 2181,
	},
	{
		id: 6,
		name: "Oct",
		rfis: 1590,
		change_orders: 9000,
		non_compliance_reports: 3500,
	},
];

const fakeColumns = [
	{
		field: "name",
		headerName: "Name",
		flex: 0.4,
	},
	{
		field: "rfis",
		headerName: "RFIs",
		flex: 0.4,
	},
	{
		field: "change_orders",
		headerName: "Change Orders",
		flex: 0.4,
	},
	{
		field: "non_compliance_reports",
		headerName: "Non Compliance Reports",
		flex: 0.4,
	},
];

const COColumns = [
	{
		field: "project",
		headerName: "Project",
		flex: 0.4,
	},
	{
		field: "scope",
		headerName: "Scope",
		flex: 0.2,
		renderCell: (params) => `${params.value}`,
	},
	{
		field: "background",
		headerName: "Background",
		flex: 0.2,
		renderCell: (params) => `${params.value}`,
	},
];

const RFIColumns = [
	{
		field: "to",
		headerName: "To",
		flex: 0.4,
	},
	{
		field: "from",
		headerName: "From",
		flex: 0.4,
	},
	{
		field: "question",
		headerName: "Question",
		flex: 0.2,
		renderCell: (params) => `${params.value}`,
	},
	{
		field: "project",
		headerName: "Project",
		flex: 0.2,
		renderCell: (params) => `${params.value}`,
	},
];

const Row3 = ({
	changeOrderData,
	rfiData,
	listData,
	error,
	errMsg,
	user,
	payrollData,
	searchPayrollFilters,
	setSearchPayrollFilters,
}) => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const [renderedCOData, setRenderedCOData] = useState([]);
	const [renderedRFIData, setRenderedRFIData] = useState([]);
	const last52Saturdays = Array.from({ length: 52 }, (_, i) =>
		dayjs().endOf("week").subtract(i, "week").format("MM/DD/YYYY")
	);
	//api call in week number but value in string
	const handleWeekStartChange = (value) => {
		const year = dayjs(value, "MM/DD/YYYY").year();
		const weekNumber = dayjs(value, "MM/DD/YYYY").week();
		setSearchPayrollFilters((prevState) => ({
			...prevState, // Keep the other existing fields
			yearStart: year, // Update field1
			weekNumberStart: weekNumber, // Update field2
		}));
	};

	//api call in week number but value in string
	const handleWeekEndChange = (value) => {
		const year = dayjs(value, "MM/DD/YYYY").year();
		const weekNumber = dayjs(value, "MM/DD/YYYY").week();
		setSearchPayrollFilters((prevState) => ({
			...prevState, // Keep the other existing fields
			yearEnd: year, // Update field1
			weekNumberEnd: weekNumber, // Update field2
		}));
	};

	useEffect(() => {
		//setAllTimecards(data);
		let renderedData = [];
		let renderedData2 = [];
		//map through array of objectsw
		if (listData?.co?.data.data) {
			renderedData = listData?.co?.data.data[0].results.map((co) => ({
				id: co._id,
				project: co.project,
				scope: co.scope,
				reason: co.reason,
				background: co.background,
				createdAt: dayjs(co.createdAt).format("MM-DD-YYYY"),
			}));

			renderedData2 = listData?.rfi?.data.data[0].results.map((rfi) => ({
				id: rfi._id,
				to: rfi.to,
				from: rfi.from,
				question: rfi.question,
				project: rfi.project,
				createdAt: dayjs(rfi.createdAt).format("MM-DD-YYYY"),
			}));

			setRenderedCOData(renderedData);
			setRenderedRFIData(renderedData2);
		}
	}, [listData?.co?.data.data, listData?.rfi?.data.data]);
	return (
		<>
			<DashBoardBox gridArea="g" theme={theme} colors={colors}>
				<Stack direction="row" spacing={2} justifyContent="space-between">
					<AnalyticsBoxHeader3 title="Change Order" subtitle="(Summary)" />
				</Stack>
				{errMsg?.co && changeOrderData.length === 0 ? (
					<Alert severity="error">{errMsg.co}</Alert>
				) : null}
				<ResponsiveContainer width="95%" height="150px">
					{changeOrderData.length === 0 ? (
						<Box />
					) : (
						<Box
							p="0 0.5rem"
							height="85%"
							sx={{
								"& .MuiDataGrid-root": {
									color: `${colors.grey[300]}`,
									border: "none",
								},
								"& .MuiDataGrid-cell": {
									borderBottom: `1px solid ${colors.grey[800]} !important`,
								},
								"& .MuiDataGrid-columnSeparator": {
									visibility: "hidden",
								},
							}}
						>
							<DataGrid
								rowHeight={25}
								columnHeaderHeight={25}
								hideFooter={true}
								rows={renderedCOData || []}
								columns={COColumns}
							/>
						</Box>
					)}
				</ResponsiveContainer>
			</DashBoardBox>
			<DashBoardBox gridArea="h" theme={theme} colors={colors}>
				<Stack direction="row" spacing={2} justifyContent="space-between">
					<AnalyticsBoxHeader3 title="RFI" subtitle="(Summary)" />
				</Stack>
				{errMsg?.rfi && rfiData.length === 0 ? (
					<Alert severity="error">{errMsg.rfi}</Alert>
				) : null}
				<ResponsiveContainer width="95%" height="150px">
					{rfiData.length === 0 ? (
						<Box />
					) : (
						<Box
							p="0 0.5rem"
							height="85%"
							sx={{
								"& .MuiDataGrid-root": {
									color: `${colors.grey[300]}`,
									border: "none",
								},
								"& .MuiDataGrid-cell": {
									borderBottom: `1px solid ${colors.grey[800]} !important`,
								},
								"& .MuiDataGrid-columnSeparator": {
									visibility: "hidden",
								},
							}}
						>
							<DataGrid
								rowHeight={25}
								columnHeaderHeight={25}
								hideFooter={true}
								rows={renderedRFIData || []}
								columns={RFIColumns}
							/>
						</Box>
					)}
				</ResponsiveContainer>
			</DashBoardBox>
			{user.role === "Company Admin" ? (
				<DashBoardBox gridArea="i" theme={theme} colors={colors}>
					<Stack direction="row">
						<AnalyticsBoxHeader3 title="Total Wages" subtitle="($)" />
						<Box sx={{ py: 1 }}>
							<CustomSelect
								value={last52Saturdays[7]}
								optionsArray={last52Saturdays}
								handleChange={(value) => handleWeekStartChange(value)}
							/>
						</Box>
						<Box sx={{ py: 1 }}>
							<CustomSelect
								value={last52Saturdays[0]}
								optionsArray={last52Saturdays}
								handleChange={(value) => handleWeekEndChange(value)}
							/>
						</Box>
					</Stack>
					{error?.sw ? <Alert severity="error">{errMsg.sw}</Alert> : null}
					{payrollData && payrollData.length === 0 ? (
						<Box height="0%" />
					) : (
						<ResponsiveContainer width="100%" height="80%">
							<LineChart
								width={300}
								height={200}
								data={payrollData}
								margin={{
									top: 15,
									right: 0,
									left: 0,
									bottom: 20,
								}}
							>
								<CartesianGrid vertical={false} stroke={colors.grey[700]} />
								<XAxis
									dataKey="weekLabel"
									stroke={colors.grey[100]}
									scale="point" // Ensures even spacing
									minTickGap={5} // Prevents overlapping
									ticks={
										payrollData
											?.filter(
												(_, index) =>
													index % Math.ceil(payrollData.length / 6) === 0
											) // Pick 6 evenly spaced ticks
											.map((entry) => entry.weekLabel) // Extract only labels for X-Axis
									}
									tick={({ x, y, payload }) => {
										const dataItem = payrollData?.find(
											(item) => item.weekLabel === payload.value
										);

										return (
											<text
												x={x}
												y={y}
												dy={16}
												textAnchor="middle"
												fill={
													dataItem?.yearPaid === dayjs().year()
														? "white"
														: "grey"
												}
												style={{ fontSize: 10 }}
											>
												{payload.value}
											</text>
										);
									}}
								/>

								<YAxis
									yAxisId="left"
									tickLine={false}
									axisLine={false}
									stroke={colors.grey[100]}
								/>
								<YAxis
									yAxisId="right"
									orientation="right"
									tickLine={false}
									axisLine={false}
									stroke={colors.grey[100]}
								/>
								<Tooltip
									content={({ payload }) => {
										// Check if there's data in the tooltip payload
										if (payload && payload.length > 0) {
											const { weekLabel, totalWages } = payload[0].payload; // Access the weekLabel (tick value)
											return (
												<div className="custom-tooltip">
													<p>
														<strong>Week: {weekLabel}</strong>
													</p>{" "}
													{/* Display the week label */}
													<p>
														<strong>Total Wages: ${totalWages}</strong>
													</p>{" "}
													{/* Display the total wages */}
												</div>
											);
										}
										return null; // Return nothing if no data in payload
									}}
								/>
								<Legend
									height={20}
									wrapperStyle={{
										margin: "0 0 10px 0",
									}}
								/>
								<Line
									yAxisId="left"
									type="monotone"
									dataKey="totalWages"
									stroke={colors.greenAccent[500]}
								/>
								{/* <Line
									yAxisId="right"
									type="monotone"
									dataKey="ot"
									stroke={colors.redAccent[500]}
								/> */}
							</LineChart>
						</ResponsiveContainer>
					)}
				</DashBoardBox>
			) : null}
		</>
	);
};

export default Row3;
